import React from 'react';
import SaveOrCancelToolBar from '../../../components/SaveOrCancelToolBar/SaveOrCancelToolBar';
import { useFormContext } from 'react-hook-form';

interface SaveCancelToolBarProps {
  loading?: boolean;
  onCancel?: () => void;
}

export const SaveCancelToolBar = (props: SaveCancelToolBarProps) => {
  const { reset: resetForm, getValues } = useFormContext();
  const acceptSelfBillingOrderValue = getValues('acceptSelfBillingOrder');

  return (
    <SaveOrCancelToolBar
      onCancel={
        props.onCancel
          ? () => {
              resetForm();
              props.onCancel && props.onCancel();
            }
          : undefined
      }
      saveButtonLabel='Enregistrer & Soumettre pour vérification'
      saveButtonDisabled={props.loading || !acceptSelfBillingOrderValue}
      loading={props.loading}
    />
  );
};
