import React, { useCallback } from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  DateInput,
  useRedirect,
  useCreate,
  useUpdate,
  useNotify,
  DeleteButton,
  NumberInput,
} from 'react-admin';
import { SimpleFormToolbar } from '../layout/SimpleFormToolbar';
import './FormStyle.scss';
import Empty, { EmptyCategoryEnum } from '../layout/Empty';

export const LicenseesList = () => {
  return (
    <List exporter={false} pagination={false} empty={<Empty category={EmptyCategoryEnum.LICENSEE} />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source='identity.firstName' label='Prénom' sortable={false} />
        <TextField source='identity.lastName' label='Nom' sortable={false} />
        <EditButton />
        <DeleteButton mutationMode='pessimistic' confirmTitle='' />
      </Datagrid>
    </List>
  );
};

interface LicenseeFormProps {
  type: 'create' | 'edit';
}

const LicenseesForm = (props: LicenseeFormProps) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const [create] = useCreate();
  const [update] = useUpdate();

  const save = useCallback(
    async (values: any) => {
      try {
        if (props.type === 'create') {
          await create('licensees', { data: values }, { returnPromise: true });
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        } else {
          await update('licensees', { id: values.id, data: values }, { returnPromise: true });
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        }
        redirect('list', 'licensees');
      } catch (error: any) {
        if (error.body) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body;
        }
      }
    },
    [create, notify, redirect, props.type, update],
  );

  return (
    <SimpleForm onSubmit={save} toolbar={<SimpleFormToolbar redirectTo='list' resource='licensees' />}>
      {props.type === 'create' ? <p className='formTitle'>Création d&apos;un licencié</p> : null}
      <div className='formContainer'>
        <TextInput
          label='Numéro de licence'
          source='licenseNumber'
          style={{ marginRight: '20px' }}
          parse={(v: string) => v}
        />
        <NumberInput
          label='Numéro de maillot'
          source='jerseyNumber'
          style={{ marginRight: '20px', width: '200px' }}
          min='0'
          max='999'
          step='1'
        />
      </div>
      <div className='formContainer'>
        <TextInput
          source='identity.firstName'
          label='Prénom'
          style={{ marginRight: '20px' }}
          isRequired
          parse={(v: string) => v}
        />
        <TextInput
          source='identity.middleName'
          label='Seconds prénoms'
          style={{ marginRight: '20px' }}
          parse={(v: string) => v}
        />
        <TextInput
          source='identity.lastName'
          label='Nom'
          style={{ marginRight: '20px' }}
          isRequired
          parse={(v: string) => v}
        />
      </div>
      <div className='formContainer'>
        <TextInput
          source='identity.phoneNumber'
          label='Téléphone'
          placeholder='Ex: 0606060606'
          type='tel'
          format={(value) => value?.replace(/[^\d+]/g, '')}
          style={{ marginRight: '20px' }}
          parse={(v: string) => v}
        />
        <DateInput source='identity.dateOfBirth' label='Date de naissance' style={{ marginRight: '20px' }} />
      </div>
    </SimpleForm>
  );
};

export const LicenseesEdit = () => (
  <Edit>
    <LicenseesForm type='edit' />
  </Edit>
);

export const LicenseesCreate = () => (
  <Create>
    <LicenseesForm type='create' />
  </Create>
);
