import React from 'react';
import { Dialog, styled, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'react-admin';
import { useFormContext } from 'react-hook-form';

export enum EModalType {
  RIB = 'rib',
  WARRANT = 'warrant',
  WARRANT_AND_RIB = 'warrant_and_rib',
  CREATE = 'create',
  EDIT = 'edit',
}

const modalDatas: Record<string, string[]> = {
  create: [
    'Vos données ont bien été enregistrées et sont en cours de vérification.',
    'Notre équipe administrative vous contactera si besoin.',
    "Votre mandat d'auto-facturation vous sera envoyé d'ici quelques jours, ​à l'adresse mail du représentant légal.",
    'Pensez à vérifier vos spams.',
  ],
  rib: [
    'Votre changement de RIB a bien été pris en compte. Il est en cours de vérification.',
    'Notre équipe administrative vous contactera si besoin.',
  ],
  warrant: [
    'Vos modifications ont bien été enregistrées et sont en cours de vérification.',
    "Un nouveau mandat d'auto-facturation sera édité et vous sera envoyé d'ici quelques jours, à l'adresse mail du représentant légal.",
    'Pensez à vérifier vos spams.',
  ],
  warrant_and_rib: [
    'Vos modifications ont bien été enregistrées et sont en cours de vérification.',
    'Notre équipe administrative vous contactera si besoin.',
    "En cas de changement sur vos données, autre que RIB, un nouveau mandat d'auto-facturation sera édité et vous sera envoyé d'ici quelques jours, à l'adresse mail du représentant légal.",
    'Pensez à vérifier vos spams.',
  ],
  edit: [
    "Vous vous apprêtez à modifier des données liées au mandat d'auto-facturation.",
    "Ces modifications engendreront une nouvelle vérification et l'édition d'un nouveau mandat d'auto-facturation.",
    'Etes-vous sûr de vouloir continuer ?',
  ],
};

export const ModalDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    textAlign: 'center',
    backgroundColor: '#141414',
    color: 'white',
    padding: 20,
    border: '3px solid #64DCBE',
    borderRadius: 20,
  },
});

interface BillingModalProps {
  type: EModalType | undefined;
  open: boolean;
  displayCancelButton: boolean;
  onClose: () => void;
  setCanEditForm: () => void;
}

const BillingModal = ({ open, onClose, type, setCanEditForm, displayCancelButton }: BillingModalProps) => {
  const { setValue } = useFormContext();

  return (
    <ModalDialog open={open}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          zIndex: 10,
        }}
      >
        <div onClick={() => onClose()} style={{ cursor: 'pointer' }}>
          <CloseIcon htmlColor='#fff' />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 20,
          padding: '25px 15px',
        }}
      >
        {type &&
          modalDatas[type].map((text, idx) => (
            <Typography key={idx} style={{ fontWeight: 'bold' }}>
              {text}
            </Typography>
          ))}
        {
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: 20,
            }}
          >
            {displayCancelButton && (
              <Button label='Annuler' variant='contained' color='primary' onClick={() => onClose()} />
            )}
            <Button
              label='Continuer'
              variant='outlined'
              color='primary'
              onClick={() => {
                if (type === EModalType.EDIT) {
                  setCanEditForm();
                  setValue('acceptSelfBillingOrder', false);
                }
                onClose();
              }}
            />
          </div>
        }
      </div>
    </ModalDialog>
  );
};

export default BillingModal;
