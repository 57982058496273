import { required, regex } from 'react-admin';
import { VAT_REGIME } from '../../../../models/billingInformation';

const isVatNumberRequired = (value: any, allValues: any) => {
  if (allValues.vatRegime !== VAT_REGIME.NON_ASSUJETTI && !value) {
    return `Ce champ est requis`;
  }

  return undefined;
};

const ribRequired = (value: any, allValues: any) => {
  if (!value) {
    return `Veuillez importer un fichier pdf`;
  }
  return undefined;
};

const isAcceptSelfBillingTrue = (value: any, allValues: any) => {
  if (!value) {
    return `Veuillez cocher la case pour continuer`;
  }
  return undefined;
};

export const formValidations = {
  legalAgentSurname: [required()],
  legalAgentFirstname: [required()],
  legalAgentFunction: [required()],
  legalAgentEmail: [required(), regex(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Ce champ est invalide')],
  phoneIndicator: [required()],
  legalAgentPhoneNumber: [required(), regex(/^\d+$/, 'Ce champ est invalide')],
  legalStatus: [required()],
  legalEntityName: [required()],
  registrationCity: [required()],
  street: [required()],
  city: [required()],
  zipcode: [required(), regex(/^\d{5}$/, 'Ce champ est invalide')],
  siret: [required(), regex(/^\d{14}$/, 'Ce champ est invalide')],
  vatNumber: [isVatNumberRequired, regex(/^FR\d{2}\d{9}$/, 'Ce champ est invalide')],
  vatRegime: [required()],
  acceptSelfBillingOrder: [isAcceptSelfBillingTrue],
  ribInputFileRequired: [ribRequired], // Don't use required() because it add a "*" under the field
  ribInputFileNotRequired: [],
};
