import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { ReactNode, useMemo } from 'react';
import './StatusLabel.scss';

export enum StatusLabelVariant {
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  INFO = 'INFO',
}

interface StatusLabelProps {
  variant: StatusLabelVariant;
  label: string;
}

const StatusLabel = (props: StatusLabelProps) => {
  const variant: { color: string; icon?: ReactNode } = useMemo(() => {
    switch (props.variant) {
      case StatusLabelVariant.DANGER:
        return { color: 'red', icon: <ErrorOutlineIcon htmlColor='white' /> };
      case StatusLabelVariant.INFO:
        return { color: 'cyan', icon: <InfoIcon htmlColor='white' /> };
      case StatusLabelVariant.SUCCESS:
        return { color: 'green', icon: <CheckIcon htmlColor='white' /> };
      default:
        return { color: 'orange', icon: <WarningAmberIcon htmlColor='white' /> };
    }
  }, [props.variant]);

  return (
    <div className='base-status-label' style={{ color: 'white', backgroundColor: variant.color }}>
      {variant.icon}
      {`Statut : ${props.label}`}
    </div>
  );
};

export default StatusLabel;
