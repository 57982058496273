import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { UserManager } from 'oidc-client';

const issuer = process.env.REACT_APP_OIDC_ISSUER;
const clientId = process.env.REACT_APP_CLIENT_ID;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;

const userManager = new UserManager({
  authority: issuer,
  client_id: clientId,
  response_type: 'code',
  redirect_uri: redirectUri,
  scope: 'openid api.clubs api.partners api.sports api.individuals api.addresses api.associations api.championships',
  metadataUrl: `${issuer}/.well-known/openid-configuration/`,
});

let currentUserManager = userManager;

export const useUserManager = singletonHook(userManager, () => {
  const [state] = useState(userManager);
  currentUserManager = state;
  return state;
});

export const getUserManager = () => currentUserManager;
