
export const extractFileNameFromUrl = (url: string): string => {
  const regex = /\/([^\\/?]+\.pdf)(\?|$)/;
  const match = url.match(regex);
  return match ? match[1] : url;
};

export const downloadFileFromBlob = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
