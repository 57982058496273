import { useEffect } from 'react';
import { useLogin } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { getAuthenticationStatus } from './services/hooks';

const LoginForm = () => {
  const [searchParams] = useSearchParams();
  const login = useLogin();

  useEffect(() => {
    const { isLoggingIn } = getAuthenticationStatus();
    if (isLoggingIn) {
      return;
    }
    const code = searchParams.get('code') || undefined;
    const state = searchParams.get('state') || undefined;

    login({ code, state }).catch((error: string) => {
      console.error(error);
    });
  }, []);
  return <></>;
};

export default LoginForm;
