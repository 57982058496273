import React from 'react';
import loadingIcon from './loading.svg';

function NotValidated() {
  return (
    <div
      style={{
        marginTop: '100px',
        marginLeft: '10px',
        padding: '30px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img src={loadingIcon} width='20%' />
      <p style={{ fontSize: '30px', marginTop: 50, marginBottom: '5px', color: '#64DCBE' }}>
        Votre club est en attente d&#39;activation
      </p>

      <p style={{ fontSize: '14px', marginTop: 20, textAlign: 'center' }}>
        Nous devons vérifier les informations de votre club.
        <br /> Pour toute question vous pouvez nous contacter via{' '}
        <a href='mailto:partenaires@champs.fr' style={{ color: '#64DCBE', textDecoration: 'none' }}>
          partenaires@champs.fr
        </a>
      </p>
    </div>
  );
}

export default NotValidated;
