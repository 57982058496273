import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import { SaveButton, Toolbar } from 'react-admin';
import './SaveOrCancelToolBar.scss';

interface SaveOrCancelToolBarProps {
  loading?: boolean;
  onCancel?: () => void;
  saveButtonLabel: string;
  saveButtonDisabled: boolean;
}

const SaveOrCancelToolBar = (props: SaveOrCancelToolBarProps) => {

  return (
    <Toolbar
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      <SaveButton
        disabled={props.saveButtonDisabled}
        label={props.saveButtonLabel}
        type='submit'
        icon={props.loading ? <RefreshIcon className='spin' /> : <SaveIcon />}
      />
      {props.onCancel && (
        <SaveButton
          icon={<CloseIcon />}
          label='Annuler'
          color='secondary'
          alwaysEnable={true}
          variant='outlined'
          onClick={(e) => {
            e.preventDefault();
            props.onCancel?.();
          }}
        />
      )}
    </Toolbar>
  );
};

export default SaveOrCancelToolBar;
