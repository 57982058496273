import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  Button,
  useRecordContext,
  useRedirect,
  useNotify,
  useCreate,
  useUpdate,
  ReferenceField,
} from 'react-admin';

import { useController } from 'react-hook-form';

import './FormStyle.scss';

import { useResourcePath } from '../services/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, Tooltip } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import { SimpleFormToolbar } from '../layout/SimpleFormToolbar';
import Empty, { EmptyCategoryEnum } from '../layout/Empty';
import { Club, ValidationStatus } from '../models/club';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const ClubSelectField = () => {
  const resourcePath = useResourcePath();
  const record: Club = useRecordContext();
  const redirect = useRedirect();

  return (
    <Button
      label='Administrer'
      onClick={() => {
        resourcePath.pushResourcePath({ resource: 'clubs', id: record.id.toString() });
        if (record.validationStatus === ValidationStatus.VALIDATED) {
          redirect('/dashboard');
        } else {
          redirect('/home');
        }
      }}
    />
  );
};

interface ClubFormProps {
  type: 'create' | 'edit';
}

const ClubForm = (props: ClubFormProps) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const [displaySportAddRequestInput, setDisplaySportAddRequestInput] = useState(false);
  const [sportRequestValue, setSportRequestValue] = useState('');

  const [create] = useCreate();
  const [update] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      try {
        const newData = values;

        if (props.type === 'create') {
          await create('clubs', { data: newData }, { returnPromise: true });
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        } else {
          await update('clubs', { id: values.id, data: newData }, { returnPromise: true });
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        }
        redirect('list', 'clubs');
      } catch (error: any) {
        if (error.body) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body;
        }
      }
    },
    [create, notify, redirect, props.type, update],
  );

  return (
    <SimpleForm onSubmit={save} toolbar={<SimpleFormToolbar withDelete={false} redirectTo={'list'} resource='clubs' />}>
      {/* mandatory */}
      <p className='formTitle' style={{ color: '#64DCBE' }}>
        Données générales sur le club
      </p>
      <div className='formContainer'>
        <TextInput
          label='Nom'
          source='name'
          style={{ marginRight: '20px', width: '420px' }}
          isRequired
          parse={(v: string) => v}
        />

        <ReferenceInput label='Fédération' source='association' reference='associations' isRequired>
          <SelectInput
            label='Fédération'
            optionText='name'
            style={{ marginRight: '20px', width: '420px' }}
            isRequired
            disabled={props.type == 'edit'}
          />
        </ReferenceInput>

        <ReferenceInput label='Sport' source='sport' reference='sports' isRequired>
          <SelectInput
            optionText='name'
            optionValue='id'
            style={{ marginRight: '20px', width: '420px' }}
            isRequired
            disabled={props.type == 'edit'}
          />
        </ReferenceInput>

        <div style={{ textAlign: 'center', marginTop: '3px', marginLeft: '50px' }}>
          {displaySportAddRequestInput ? (
            <FormControl style={{ verticalAlign: 'middle' }}>
              <MuiTextField
                value={sportRequestValue}
                onChange={(event) => setSportRequestValue(event.target.value)}
                label='Sport'
                variant='standard'
                sx={{
                  width: '200px',
                  '& .MuiInputLabel-root[data-shrink="false"]': {
                    marginTop: '-1px',
                  },
                }}
              />
              <Button
                label='Envoyer la demande'
                disabled={sportRequestValue.length === 0}
                onClick={() => {
                  create('add_request', {
                    data: {
                      type: 'sport',
                      value: sportRequestValue,
                    },
                    meta: { useResourcePath: false },
                  });
                  setDisplaySportAddRequestInput(false);
                }}
              />
            </FormControl>
          ) : (
            <Typography style={{ fontSize: '14px', marginTop: '10px' }}>Vous ne trouvez pas votre sport ?</Typography>
          )}
          <Button
            label={displaySportAddRequestInput ? 'Fermer' : 'Demander à ajouter un sport'}
            onClick={() => {
              setDisplaySportAddRequestInput(!displaySportAddRequestInput);
            }}
          />
        </div>
      </div>
      <div className='formContainer'>
        <section style={{ width: '98%', display: 'flex' }}>
          <TextInput
            source='address.street'
            label='N° et Rue'
            style={{ flex: 1, height: '50px' }}
            isRequired
            parse={(v: string) => v}
          />
        </section>
        <TextInput
          source='address.zipcode'
          label='Code postal'
          style={{ marginRight: '20px', height: '50px' }}
          isRequired
          parse={(v: string) => v}
        />
        <TextInput
          source='address.city'
          label='Ville'
          style={{ marginRight: '20px', width: '420px', height: '50px' }}
          isRequired
          parse={(v: string) => v}
        />
        <TextInput
          source='address.country'
          label='Pays'
          style={{ marginRight: '20px', height: '50px', width: '420px' }}
          isRequired
          parse={(v: string) => v}
        />
      </div>

      <p
        className='formTitle'
        style={{
          color: '#64DCBE',
          borderTop: '2px solid #64DCBE',
          paddingTop: '10px',
          marginTop: '80px',
          width: '98%',
        }}
      >
        Contacts des responsables diffusion CHAMP&apos;S au sein de votre club
      </p>
      <div className='formContainer'>
        <div style={{ marginRight: '20px' }}>
          <>
            <TextInput source='referralName' label='Nom du référent' isRequired parse={(v: string) => v} />
            <Tooltip title="Afin que vous bénéficiez de tout le potentiel de CHAMP'S, il est important que vous désignez un référent qui aura pour rôle de faire le lien entre votre club et nous. Nous lui communiquerons les informations techniques nécessaires pour les diffusions de vos matchs ainsi que les nouvelles fonctionnalités développées. Il sera également votre porte parole pour nous remonter des améliorations souhaitées par votre club et/ou vos fans.​">
              <HelpCenterIcon style={{ bottom: '-25%', position: 'relative' }} />
            </Tooltip>
          </>
        </div>
        <TextInput
          source='referralFunction'
          label='Fonction'
          style={{ marginRight: '20px' }}
          isRequired
          parse={(v: string) => v}
        />
        <TextInput
          source='referralPhoneNumber'
          label='Téléphone'
          type='tel'
          placeholder='Ex: 0606060606'
          format={(value) => value?.replace(/[^\d+]/g, '')}
          style={{ marginRight: '20px', height: '50px' }}
          isRequired
          parse={(v: string) => v}
        />
        <TextInput
          source='referralEmail'
          label='Email'
          type='email'
          style={{ marginRight: '20px', width: '420px', height: '50px' }}
          isRequired
          parse={(v: string) => v}
        />
      </div>
      <div className='formContainer'>
        <TextInput
          source='substituteName'
          label='Nom du suppléant'
          style={{ marginRight: '20px' }}
          parse={(v: string) => v}
        />
        <TextInput
          source='substituteFunction'
          label='Fonction'
          style={{ marginRight: '20px' }}
          parse={(v: string) => v}
        />
        <TextInput
          source='substitutePhoneNumber'
          label='Téléphone'
          type='tel'
          placeholder='Ex: 0606060606'
          format={(value) => value?.replace(/[^\d+]/g, '')}
          style={{ marginRight: '20px', height: '50px' }}
          parse={(v: string) => v}
        />
        <TextInput
          source='substituteEmail'
          label='Email'
          type='email'
          style={{ marginRight: '20px', width: '420px', height: '50px' }}
          parse={(v: string) => v}
        />
      </div>

      {/* optional */}
      <p
        className='formTitle'
        style={{
          color: '#64DCBE',
          borderTop: '2px solid #64DCBE',
          paddingTop: '10px',
          marginTop: '80px',
          width: '98%',
        }}
      >
        Médias du club <em>(facultatif)</em>
      </p>
      <div className='formContainer'>
        <TextInput
          source='socialNetworkUrl'
          label='Réseau social'
          helperText='Lien vers un réseau social du club (Facebook, Instagram, etc)'
          type='url'
          style={{ marginRight: '20px', width: '420px' }}
          parse={(v: string) => v}
        />
        <TextInput
          source='websiteUrl'
          label='Site internet'
          type='url'
          style={{ marginRight: '40px', width: '420px' }}
          parse={(v: string) => v}
        />
      </div>
    </SimpleForm>
  );
};

const ColorInput = () => {
  const clubColor = useController({ name: 'clubColor', defaultValue: '#000000' });
  const clubColorSecondary = useController({ name: 'clubColorSecondary', defaultValue: '#000000' });
  return (
    <FormControl>
      <Typography style={{ fontSize: '14px' }}>Couleurs du club</Typography>
      <div style={{ display: 'flex', marginTop: '-7px' }}>
        <section style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <Input
            sx={{
              '& .MuiInput-input': {
                height: '35px',
                width: '32px',
              },
            }}
            {...clubColor.field}
            type='color'
            disableUnderline
            required
          />
          <p style={{ fontSize: '14x', marginLeft: '10px' }}>Principale</p>
        </section>
        <section style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <Input
            sx={{
              '& .MuiInput-input': {
                height: '35px',
                width: '32px',
              },
            }}
            {...clubColorSecondary.field}
            type='color'
            disableUnderline
            required
          />
          <p style={{ fontSize: '14x', marginLeft: '10px' }}>Secondaire</p>
        </section>
      </div>
    </FormControl>
  );
};

export const ClubsList = () => {
  const resourcePath = useResourcePath();
  const redirect = useRedirect();

  // prevent an error when the user use the return from the web browser
  useEffect(() => {
    if (resourcePath.resourcePath.value.length) {
      redirect('/dashboard');
    }
  }, []);

  return (
    <List exporter={false} pagination={false} empty={<Empty category={EmptyCategoryEnum.CLUB} />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source='name' label='Nom' />
        <TextField source='statsCode' label='Code club' />
        <ReferenceField source='sport' reference='sports'>
          <TextField source='name' label='Nom' />
        </ReferenceField>
        <TextField source='referralEmail' label='Adresse email' />
        <ClubSelectField />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ClubEdit = () => {
  return (
    <Edit>
      <ClubForm type='edit' />
    </Edit>
  );
};

export const ClubCreate = () => {
  return (
    <Create>
      <ClubForm type='create' />
    </Create>
  );
};
