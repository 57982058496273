import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

interface ResourcePath {
  resource: string;
  id?: string;
}

class ResourcePathManager {
  private getResourcePathFromLocalStorage() {
    const resourcePath = localStorage.getItem('resourcePath');
    return resourcePath ? (JSON.parse(resourcePath) as ResourcePath[]) : [];
  }

  public get resourcePath() {
    const r = this.getResourcePathFromLocalStorage();
    return {
      value: r,
      get asString() {
        return r.map((x) => x.resource + (x.id ? `/${x.id}` : '')).join('/');
      },
    };
  }

  public pushResourcePath(resourcePathToPush: ResourcePath) {
    const currentResourcePath = this.resourcePath.value;
    if (!currentResourcePath.find((x) => x.resource === resourcePathToPush.resource)) {
      currentResourcePath.push(resourcePathToPush);
      localStorage.setItem('resourcePath', JSON.stringify(currentResourcePath));
    }
  }

  public popResourcePath() {
    const currentResourcePath = this.resourcePath.value;
    currentResourcePath.pop();
    localStorage.setItem('resourcePath', JSON.stringify(currentResourcePath));
  }

  public clearResourcePath() {
    localStorage.removeItem('resourcePath');
  }
}

class AuthenticationStatusManager {
  public isLoggingIn;

  constructor() {
    this.isLoggingIn = false;
    this.setIsLoggingIn = this.setIsLoggingIn.bind(this);
  }

  public setIsLoggingIn(value: boolean) {
    this.isLoggingIn = value;
  }
}

const resourcePath = new ResourcePathManager();
const authenticationStatus = new AuthenticationStatusManager();

let currentResourcePath = resourcePath;
let currentAuthenticationStatus = authenticationStatus;

export const useResourcePath = singletonHook(currentResourcePath, () => {
  const [state] = useState(resourcePath);
  currentResourcePath = state;
  return state;
});

export const useIsLoggingIn = singletonHook(currentAuthenticationStatus, () => {
  const [state] = useState(authenticationStatus);
  currentAuthenticationStatus = state;
  return state;
});

export const getResourcePath = () => currentResourcePath;
export const getAuthenticationStatus = () => currentAuthenticationStatus;
