// in src/admin/index.tsx
import { Resource, fetchUtils, Loading, CustomRoutes, Admin } from 'react-admin';
import adminDataProvider from './services/dataProvider';
import authProvider from './oidcProvider';
import LoginForm from './LoginForm';
import { BrowserRouter, Route } from 'react-router-dom';
import { getUserManager } from './services/userManager';
import { ClubCreate, ClubEdit, ClubsList } from './lists/ClubsList';
import { AdminLayout } from './layout/AdminLayout';
import { QueryClient } from 'react-query';
import React from 'react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import fr from 'ra-language-french';
import { TeamsCreate, TeamsEdit, TeamsList } from './lists/TeamsList';
import { LicenseesCreate, LicenseesEdit, LicenseesList } from './lists/Licensees';
import {
  CompetitionLocationsCreate,
  CompetitionLocationsEdit,
  CompetitionLocationsList,
} from './lists/CompetitionLocationsList';
import { CompetitionsCreate, CompetitionsEdit, CompetitionsList } from './lists/CompetitionList';
import { TestCompetitionsList, TestCompetitionsEdit, TestCompetitionsCreate } from './lists/TestCompetitionList';
import { defaultTheme } from 'react-admin';
import pink from '@mui/material/colors/red';
import Dashboard from './pages/Dashboard';
import Billing from './pages/Billing/Billing';
import NotValidated from './pages/NotValidated';
import { PaletteType } from '@material-ui/core';
import IncomesList from './lists/IncomesList';

const userManager = getUserManager();

const asyncWait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const httpClient = async (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const uri = new URL(url);
  uri.pathname = uri.pathname.replace('//', '/');
  url = uri.toString();
  let user = await userManager.getUser();
  let retryCount = 0;
  while (user === null) {
    await asyncWait(1000);
    console.log('retrying');
    user = await userManager.getUser();
    if (retryCount > 2) {
      break;
    }
    retryCount++;
  }
  if (user !== null) options.headers.set('Authorization', `Bearer ${user.access_token}`);
  return fetchUtils.fetchJson(url, options);
};

const apiRoot = process.env.REACT_APP_API_ROOT || 'http://localhost:8000/api';

const dataProvider = adminDataProvider(apiRoot, httpClient);

const i18nProvider = polyglotI18nProvider(() => {
  return {
    ...fr,
    ...{
      error: {
        competition: {
          code_rencontre: {
            exists: 'Ce code de rencontre existe déjà.',
            invalid:
              'Le code de rencontre est invalide, seuls les caractères alphanumériques sont autorisés (chiffres et lettres).',
          },
        },
      },
    },
  };
}, 'fr');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
      optimisticResults: false,
      cacheTime: 0,
      onSettled(data, error: any) {
        if (error) {
          if (error.status === 401) {
            window.location.replace('/login');
          }
        }
      },
      onError: (error: any) => {
        if (error.status === 401) {
          window.location.replace('/login');
        }
      },
    },
  },
});

const App = () => {
  const theme = {
    ...defaultTheme,
    palette: {
      mode: 'dark' as PaletteType,
      primary: {
        main: '#64DCBE',
      },
      secondary: pink,
      contrastThreshold: 4.5,
    },
    components: {
      ...defaultTheme.components,
      RaLayout: {
        styleOverrides: {
          root: {
            '& .RaLayout-content': {
              padding: 0,
              backgroundColor: 'transparent',
            },
            // to avoid Dialog box bug, changing color
            'header.MuiPaper-root': {
              color: 'white',
              backgroundColor: '#141414',
            },
          },
        },
      },

      // to change color of line under input
      // MuiFilledInput: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: 'transparent',
      //       '&::before': {
      //         borderBottomColor: '#64DCBE',
      //       },
      //     },
      //   },
      // },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            '&[data-shrink="false"]': {
              marginTop: '5px',
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontStyle: 'italic',
          },
        },
      },

      // added mediaquery for smaller screens, menu displaying only Icons
      // beware: if you change the width, you need to change it too in Menu sx in AdminLayoutMenu
      RaSidebar: {
        styleOverrides: {
          root: {
            zIndex: 50,
            '@media (max-width: 1224px)': { width: '60px' },
            '& .MuiPaper-root': {
              '@media (max-width: 1224px)': { width: '60px' },
              width: '210px',
            },
            '& .RaSidebar-fixed': {
              width: '210px',
              backgroundColor: '#282828',
              height: '100vh',
              boxShadow: '5px 0px 10px black',
              '@media (max-width: 1224px)': { width: '60px' },
            },
          },
          fixed: { '@media (max-width: 1224px)': { width: '60px' } },
        },
      },
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            color: 'white',
            fontSize: 13,
            '@media (max-width: 1224px)': {
              color: 'transparent',
              width: '60px',
              overflow: 'hidden',
            },
            '&.RaMenuItemLink-active': {
              color: '#64DCBE',
              '@media (max-width: 1224px)': { color: 'transparent', backgroundColor: '#121212', width: '60px' },
            },
            '& .RaMenuItemLink-icon': {
              color: '#64DCBE',
            },
          },
        },
      },

      RaDatagrid: {
        styleOverrides: {
          root: {
            '& .RaDatagrid-headerCell': {
              color: '#64DCBE',
              borderBottom: '1px solid #64DCBE',
              backgroundColor: '#282828',
            },
            ' & .RaDatagrid-rowEven': {
              backgroundColor: 'black',
              borderBottom: 'none',
            },
            // target tr extanded preceded by rowEven
            '& .RaDatagrid-rowEven + .RaDatagrid-expandedPanel': {
              backgroundColor: 'black',
              borderBottom: '1px solid #64DCBE',
            },
            '& .RaDatagrid-rowOdd': {
              backgroundColor: '#282828',
            },
            '& .RaDatagrid-rowOdd + .RaDatagrid-expandedPanel': {
              backgroundColor: '#282828',
              borderBottom: '1px solid #64DCBE',
            },
            '& .RaDatagrid-expandIcon': {
              color: '#64DCBE',
              marginLeft: '8px',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: 'none',
          },
        },
      },
    },
  };

  return (
    <BrowserRouter>
      <Admin
        theme={theme}
        loginPage={LoginForm}
        layout={AdminLayout}
        loading={Loading}
        requireAuth
        dataProvider={dataProvider}
        authProvider={authProvider}
        queryClient={queryClient}
        i18nProvider={i18nProvider}
      >
        <Resource options={{ label: 'Clubs' }} name='clubs' list={ClubsList} edit={ClubEdit} create={ClubCreate} />
        <CustomRoutes>
          <Route path='/home' element={<NotValidated />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/billing' element={<Billing />} />
          {/* <Route path='/incomes' element={<IncomesList />} /> */}
        </CustomRoutes>
        <Resource options={{ label: 'Équipes' }} name='teams' list={TeamsList} edit={TeamsEdit} create={TeamsCreate} />
        <Resource
          options={{ label: 'Mes licenciés' }}
          name='licensees'
          list={LicenseesList}
          edit={LicenseesEdit}
          create={LicenseesCreate}
        />

        <Resource
          options={{ label: 'Lieux de compétition' }}
          name='competition_locations'
          list={CompetitionLocationsList}
          edit={CompetitionLocationsEdit}
          create={CompetitionLocationsCreate}
        />
        <Resource
          options={{ label: 'Matchs' }}
          name='competitions'
          list={CompetitionsList}
          edit={CompetitionsEdit}
          create={CompetitionsCreate}
        />
        <Resource
          options={{ label: 'Matchs de Test' }}
          name='test_competitions'
          list={TestCompetitionsList}
          edit={TestCompetitionsEdit}
          create={TestCompetitionsCreate}
        />
      </Admin>
    </BrowserRouter>
  );
};

export default App;
