import React from 'react';
import { Link } from 'react-router-dom';
import emptyIcon from './empty.svg';
import { CreateButton } from 'react-admin';

export enum EmptyCategoryEnum {
  LICENSEE = 'de Licencié',
  CLUB = 'de Club',
  COMPETITION = 'de Match',
  LOCATION = 'de Lieu de Compétition',
  TEAM = "d'Equipe",
  TEST = 'de Match de Test',
}

interface EmptyProps {
  category: EmptyCategoryEnum;
}

function Empty(props: EmptyProps) {
  return (
    <div
      style={{
        marginTop: '50px',
        marginLeft: '10px',
        padding: '50px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <img src={emptyIcon} width='20%' />
      <p style={{ fontSize: '30px', marginTop: 0, marginBottom: '5px', color: '#64DCBE' }}>
        Vous n&#39;avez pas encore ajouté {props.category}
      </p>

      {props.category !== EmptyCategoryEnum.TEST && props.category !== EmptyCategoryEnum.CLUB && (
        <p style={{ fontSize: '14px', marginBottom: '50px' }}>
          <em>
            Pour pouvoir créér un match vous devez d&#39;abord entrer un Lieu de compétition, créer des Licenciés et les
            assigner dans une Équipe.
          </em>
          {props.category === EmptyCategoryEnum.COMPETITION && (
            <em>
              <br /> <br />
              <b>Attention</b>, les Matchs que vous créez ici seront visibles dans l&#39;Application Mobile et dans la
              Régie Pub pour générer des revenus publicitaires. Si vous voulez tester le portail partenaire, la saisie
              de statistiques et visualiser le résultat sur l&#39;application mobile, merci de vous rendre dans
              l&#39;onglet{' '}
              <Link to='/test_competitions' style={{ textDecoration: 'none', color: '#64DCBE' }}>
                Matchs de test
              </Link>
              .
            </em>
          )}
          {props.category === EmptyCategoryEnum.TEAM && (
            <em>
              <br /> <br />
              Lors de la création, sélectionnez d&#39;abord votre championnat dans la liste, puis l&#39;équipe
              correspondant à votre club. Vous pourrez ensuite assigner vos Licenciés à votre équipe ! <br />{' '}
              N&#39;hésitez pas à nous contacter si votre championnat n&#39;est pas dans la liste.
            </em>
          )}
        </p>
      )}

      {props.category === EmptyCategoryEnum.TEST && (
        <p style={{ fontSize: '14px', marginBottom: '50px' }}>
          <em>
            Lorsque vous créez un match de test le championnat et les équipes sont remplis automatiquement. Vous
            n&#39;avez donc pas besoin d&#39;avoir créé des licenciés ou une équipe pour tester. <br />
            <br />
            Sur l&#39;Application Mobile : les matchs créés ici ne seront pas visibles dans le carrousel, vous pouvez
            accéder au live en tapant le code rencontre dans le champ de recherche prévu à cet effet. Vous pouvez aussi
            tester notre{' '}
            <a
              href='https://alpha.champs.fr/'
              target='_blank'
              style={{ textDecoration: 'none', color: '#64DCBE', fontWeight: 'bold' }}
              rel='noreferrer'
            >
              site de saisie de statistiques
            </a>{' '}
            avec le code rencontre de ce match.
          </em>
        </p>
      )}

      <CreateButton sx={{ backgroundColor: '#64DCBE', color: 'black', width: '100px' }} />
    </div>
  );
}

export default Empty;
