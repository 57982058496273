import React from 'react';
import { useRef } from 'react';
import { Button, FileInput, FileInputProps } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import './RibInputField.css';
import { RaFileType } from '../../../models/utils';

interface RibInputFieldProps {
  disabled?: boolean;
  updateLabel: string;
  addLabel: string;
  fileInputProps: FileInputProps;
  originalFileName?: string;
  fileNameHelperText: string;
}

export const RibInputField = (props: RibInputFieldProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { watch } = useFormContext();
  const file: RaFileType | undefined = watch(props.fileInputProps.source);

  const handleButtonClick = () => {
    if (!fileInputRef) return;
    fileInputRef.current?.click();
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6} lg={5} xl={4}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Button
            disabled={props.disabled}
            label={file || props.originalFileName ? props.updateLabel : props.addLabel}
            component='label'
            color='primary'
            variant={'outlined'}
            onClick={handleButtonClick}
            style={{
              padding: 10,
            }}
            startIcon={file ? <EditIcon /> : <AddIcon />}
          />
          <FileInput
            inputProps={{
              ref: fileInputRef,
            }}
            label=' '
            multiple={false}
            {...props.fileInputProps}
          />
        </div>
      </Grid>
      {(file || props.originalFileName) && (
        <Grid item xs={12} sm={6} style={{ marginTop: 17 }}>
          <Typography
            style={{
              color: file || props.originalFileName ? 'white' : '#FFFFFFB3',
              fontSize: 15,
              borderBottom: '1px solid white',
              width: '66%',
            }}
          >
            {file ? file.title : props.originalFileName}
          </Typography>

          <Typography style={{ fontSize: 12, marginTop: 10, color: '#FFFFFFB3', fontStyle: 'italic' }}>
            {props.fileNameHelperText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
