import React from 'react';
import { Collapse, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DownloadIcon from '@mui/icons-material/Download';
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonProps,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  TextField,
  useDataProvider,
  useNotify,
} from 'react-admin';
import BillingInformationsForm from './BillingInformationForm/BillingInformationsForm';
import { BillingInformations, downloadWarrantResponse } from '../../models/billingInformation';
import { Bill } from '../../models/club';
import { useResourcePath } from '../../services/hooks';
import { downloadFileFromBlob } from '../../utils/fileUtils';
import { BillingInformationStatusLabel } from './BillingStatusLabel';

const DownloadButton = (props: ButtonProps) => {
  return <Button color='primary' size='small' label='Télécharger' startIcon={<DownloadIcon />} {...props} />;
};

const Billing = () => {
  const [expanded, setExpanded] = useState(true);
  const dataProvider = useDataProvider();
  const resourcePath = useResourcePath();
  const clubId = resourcePath.resourcePath.value[0].id || '';
  const [data, setData] = useState<BillingInformations | undefined>();
  const notify = useNotify();

  const downloadBillFile = (id: string, fileName: string) => {
    dataProvider
      .downloadBill(clubId, id)
      .then((data: Blob) => {
        downloadFileFromBlob(data, fileName);
      })
      .catch((error: string) => {
        notify(error, {
          type: 'error',
        });
      });
  };

  const downloadFileWarrant = () => {
    dataProvider
      .downloadBillingInformationWarrant(clubId)
      .then((data: downloadWarrantResponse) => {
        downloadFileFromBlob(data.blob, data.fileName);
      })
      .catch((error: string) => {
        notify(error, {
          type: 'error',
        });
      });
  };

  const fetchBillingInformationsData = useCallback(() => {
    dataProvider
      .getBillingInformations(clubId)
      .then((data: BillingInformations) => {
        setData(data);
      })
      .catch((error: string) => {
        notify(error, { type: 'error' });
      });
  }, [clubId, dataProvider, notify]);

  useEffect(() => {
    fetchBillingInformationsData();
  }, [fetchBillingInformationsData]);

  return (
    <>
      <div style={{ padding: '30px' }}>
        <h3 style={{ color: '#64dcbe' }}>{`Informations nécessaires à l'auto-facturation`}</h3>
        <p style={{ fontSize: '14px', paddingTop: '15px', paddingBottom: '15px' }}>
          {`En diffusant vos compétitions sur la plateforme CHAMP'S, votre club peut générer des revenus. Afin que nous vous reversions ces revenus, il est nécessaire que vous autorisiez CHAMP'S SAS à établir une auto-facture. 
          Un mandat est impératif pour que nous puissions effectuer les règlements à votre club.`}
          <br />
          {`Pour pouvoir déclencher la redistribution de ces revenus, merci de renseigner les éléments demandés ci-dessous et d' importer le RIB de l'établissement habilité à facturer.`}
        </p>
        <div
          onClick={() => setExpanded((prev) => !prev)}
          style={{ display: 'flex', gap: 2, alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}
        >
          {expanded ? <ArrowDropUpIcon htmlColor='#64dcbe' /> : <ArrowDropDownIcon htmlColor='#64dcbe' />}
          <h3 style={{ color: '#64dcbe' }}>{`Mandat d'auto-facturation`}</h3>
          <div style={{ marginLeft: 14 }}>
            <BillingInformationStatusLabel rib_status={data?.ribStatus} warrant_status={data?.warrantStatus} />
          </div>
        </div>
        <Collapse in={expanded} timeout='auto'>
          <Typography paragraph style={{ fontSize: '14px' }}>
            {`Le mandat d'auto-facturation vous permet de donner expressément mandat à CHAMP'S, qui accepte d'établir en votre nom et pour votre compte les factures originales, relatives aux prestations de services réalisées au profit de CHAMP'S.`}
            <br />
            {`Une fois vos données vérifiées, vous recevrez le mandat d'auto-facturation  par e-mail, à l'adresse du représentant légal.`}
            <br />
            {`Toutes modifications apportées sur vos données engendreront la signature d'un nouveau mandat.`}
            <br />
            {`Pour les clubs rattachés à une société sportive, il conviendra de fournir les coordonnées de l'établissement habilité à facturer.`}
          </Typography>
          <BillingInformationsForm
            data={data}
            onDownloadWarrant={downloadFileWarrant}
            onSubmit={(body: BillingInformations) => {
              if (data)
                return dataProvider.updateBillingInformations(clubId, body).then(() => fetchBillingInformationsData());
              return dataProvider.createBillingInformations(clubId, body).then(() => fetchBillingInformationsData());
            }}
          />
        </Collapse>
      </div>

      <List
        resource='bills'
        exporter={false}
        actions={false}
        pagination={false}
        empty={<></>}
        sx={{
          '& .MuiPaper-root': { backgroundImage: 'none' },
        }}
      >
        <>
          <h3 style={{ color: '#64dcbe', paddingInline: 30 }}>Vos factures</h3>
          <Datagrid bulkActionButtons={false}>
            <TextField source='name' label='Nom' textAlign='left' />
            <DateField
              options={{ year: 'numeric', month: 'numeric', day: 'numeric' }}
              source='issueDate'
              label="Date d'édition"
            />
            <NumberField
              label='Montant'
              source='amount'
              locales='fr-FR'
              options={{ style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }}
            />
            <FunctionField
              textAlign='right'
              render={(record: Bill) => <DownloadButton onClick={() => downloadBillFile(record.id, record.name)} />}
            />
          </Datagrid>
        </>
      </List>
    </>
  );
};
export default Billing;
