import React, { useCallback, useState } from 'react';
import {
  Datagrid,
  Edit,
  EditButton,
  List,
  ReferenceArrayInput,
  SimpleForm,
  TextField,
  TextInput,
  SelectArrayInput,
  SelectInput,
  Create,
  useRedirect,
  useCreate,
  useNotify,
  useUpdate,
  Button,
  useGetList,
  FunctionField,
  useGetOne,
  FormDataConsumer,
} from 'react-admin';
import { useResourcePath } from '../services/hooks';
import './FormStyle.scss';

import { FormControl } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import { SimpleFormToolbar } from '../layout/SimpleFormToolbar';
import Empty, { EmptyCategoryEnum } from '../layout/Empty';
import { Club, ValidationStatus } from '../models/club';
import { Team } from '../models/team';
import { Championship } from '../models/competition';

export const TeamsList = () => {
  const AssociatedChampionship = ({ record }: { record: Team }) => {
    const { data: championship } = useGetOne<Championship>('championships', {
      id: record.associatedChampionship,
      meta: { useResourcePath: false },
    });

    return <span>{championship?.name}</span>;
  };

  const SendStreamingURL = ({ record }: { record: Team }) => {
    const notify = useNotify();
    const [displayPhoneInput, setDisplayPhoneInput] = useState(false);
    const [sendRequestValue, setSendRequestValue] = useState('');
    const [create] = useCreate();

    return (
      <div className='formContainer' style={{ justifyContent: 'flex-start' }}>
        <FormControl fullWidth>
          {displayPhoneInput ? (
            <FormControl>
              <MuiTextField
                fullWidth
                value={sendRequestValue}
                onChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setSendRequestValue(event.target.value);
                }}
                label='Numéro de téléphone'
                type='tel'
                variant='standard'
                placeholder='0606060606'
              />
              <Button
                fullWidth
                label='Envoyer'
                disabled={sendRequestValue.length === 0}
                onClick={() => {
                  create(
                    'send_team_default_ingest_url_sms',
                    {
                      data: {
                        team: record.id,
                        phone_number: sendRequestValue,
                      },
                      meta: { useResourcePath: false },
                    },
                    { returnPromise: true },
                  )
                    .then(() => {
                      notify('La demande a bien été envoyée', { type: 'success' });
                      setDisplayPhoneInput(false);
                      setSendRequestValue('');
                    })
                    .catch(() => {
                      notify('Une erreur est survenue, veuillez réessayer.', { type: 'error' });
                      setSendRequestValue('');
                    });
                }}
              />
            </FormControl>
          ) : (
            <Typography style={{ textAlign: 'center', width: '100%' }}>
              Demander à recevoir l&apos;URL de diffusion
            </Typography>
          )}
          <Button
            label={displayPhoneInput ? 'Fermer' : 'Entrer le numéro de téléphone'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDisplayPhoneInput(!displayPhoneInput);
            }}
          />
        </FormControl>
      </div>
    );
  };

  const CopyStreamingURL = ({ record }: { record: Team }) => {
    const notify = useNotify();

    return (
      <Button
        label="Copier l'URL de diffusion"
        onClick={() => {
          navigator.clipboard.writeText(record.defaultChannels.length > 0 ? record.defaultChannels[0].ingestUrl : '');
          notify("L'URL a bien été copiée dans votre presse papier.", { type: 'success' });
        }}
      />
    );
  };

  return (
    <List exporter={false} pagination={false} empty={<Empty category={EmptyCategoryEnum.TEAM} />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source='name' label='Nom' sortable={false} />
        <FunctionField
          label='Championnat associé'
          source='associatedChampionship'
          sortable={false}
          render={(record: Team) => <AssociatedChampionship record={record} />}
        />
        <FunctionField render={(record: Team) => <SendStreamingURL record={record} />} />
        <FunctionField render={(record: Team) => <CopyStreamingURL record={record} />} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

interface TeamFormProps {
  type: 'create' | 'edit';
}

const TeamsForm = (props: TeamFormProps) => {
  const resourcePath = useResourcePath();
  const redirect = useRedirect();
  const notify = useNotify();

  const [create] = useCreate();
  const [update] = useUpdate();

  const [displayChampionshipAddRequestInput, setDisplayChampionshipAddRequestInput] = useState(false);
  const [championshipRequestValue, setChampionshipRequestValue] = useState('');
  const [displayTeamAddRequestInput, setDisplayTeamAddRequestInput] = useState(false);
  const [teamRequestValue, setTeamRequestValue] = useState('');

  const clubId = resourcePath.resourcePath.value[0].id as string;
  const { data: club } = useGetOne<Club>('clubs', {
    id: clubId,
    meta: { useResourcePath: false },
  });

  // Get championships for the sport corresponding to the club with associated teams
  const { data: championships_with_associated_teams } = useGetList<Championship>(
    'championships/with_associated_teams',
    {
      meta: { useResourcePath: false },
      filter: {
        club: club?.id,
        with_assigned_teams: 'false',
      },
    },
    {
      enabled: !!club,
    },
  );

  const save = useCallback(
    async (values: any) => {
      try {
        const newData = values;
        if (props.type == 'create') {
          await create('teams', { data: newData }, { returnPromise: true });
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        } else {
          await update('teams', { id: values.id, data: newData }, { returnPromise: true });
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        }
        redirect('list', 'teams');
      } catch (error: any) {
        if (error.body) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body;
        }
      }
    },
    [create, notify, redirect, update, props.type],
  );

  return (
    <SimpleForm onSubmit={save} toolbar={<SimpleFormToolbar redirectTo='list' resource='teams' withDelete={false} />}>
      <div className='formContainer'>
        <TextInput
          source='name'
          label="Nom d'équipe interne"
          placeholder='Ex: U18 M, Senior 1...'
          helperText='Nom que vous utiliserez en interne pour votre équipe'
          style={{ marginRight: '20px' }}
          isRequired
          parse={(v: string) => v}
        />
      </div>
      <div className='formContainer'>
        <SelectInput
          label='Championnat'
          source='associatedChampionship'
          choices={championships_with_associated_teams || []}
          style={{ marginRight: '60px', width: '420px' }}
          helperText='Choisissez le championnat dans lequel évolue votre équipe'
          isRequired
          disabled={props.type == 'edit'}
        />
        {club?.validationStatus === ValidationStatus.VALIDATED && props.type == 'create' && (
          <FormControl style={{ marginTop: displayChampionshipAddRequestInput ? '2px' : '25px' }}>
            {displayChampionshipAddRequestInput ? (
              <FormControl>
                <MuiTextField
                  fullWidth
                  value={championshipRequestValue}
                  onChange={(event) => setChampionshipRequestValue(event.target.value)}
                  label='Championnat'
                  variant='standard'
                  helperText='Pour plus de clarté, pensez à épeler le championnat en toutes lettres en précisant le sport.'
                />
                <Button
                  fullWidth
                  label='Envoyer'
                  disabled={championshipRequestValue.length === 0}
                  onClick={() => {
                    create('add_request', {
                      data: {
                        type: 'championnat',
                        value: championshipRequestValue,
                      },
                      meta: { useResourcePath: false },
                    });
                    setDisplayChampionshipAddRequestInput(false);
                  }}
                />
              </FormControl>
            ) : (
              <Typography style={{ textAlign: 'center', width: '100%' }}>
                Vous ne trouvez pas votre championnat ?
              </Typography>
            )}
            <Button
              label={displayChampionshipAddRequestInput ? 'Fermer' : 'Demander à ajouter un championnat'}
              onClick={() => {
                setDisplayChampionshipAddRequestInput(!displayChampionshipAddRequestInput);
              }}
            />
          </FormControl>
        )}
      </div>
      <div className='formContainer'>
        {props.type == 'edit' && (
          <TextInput
            source='publicName'
            label='Mon équipe'
            helperText='Nom utilisé pour votre équipe lors de la diffusion'
            style={{ marginRight: '20px' }}
            isRequired
            parse={(v: string) => v}
            disabled={true}
          />
        )}
        {props.type == 'create' && (
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <SelectInput
                choices={
                  championships_with_associated_teams?.filter(
                    (champ) => champ.id === formData.associatedChampionship,
                  )[0]?.associatedTeams || []
                }
                label='Mon équipe'
                optionValue='id'
                optionText={(record) => `${record.publicName}`}
                source='id'
                style={{ marginRight: '20px' }}
                sx={{
                  '& .MuiChip-root': {
                    height: '22px',
                    marginTop: '-1px',
                  },
                }}
                helperText="Sélectionnez votre équipe dans la liste. Seules les équipes liées au championnat sélectionné apparaissent ici. Merci de bien vérifier votre choix, vous ne pourrez pas revenir en arrière une fois l'équipe créée"
                isRequired
                {...rest}
              />
            )}
          </FormDataConsumer>
        )}

        {club?.validationStatus === ValidationStatus.VALIDATED && props.type == 'create' && (
          <FormControl style={{ marginTop: displayTeamAddRequestInput ? '2px' : '25px' }}>
            {displayTeamAddRequestInput ? (
              <FormControl>
                <MuiTextField
                  fullWidth
                  value={teamRequestValue}
                  onChange={(event) => setTeamRequestValue(event.target.value)}
                  label='Championnat, nom du club, équipe'
                  variant='standard'
                  helperText='Veuillez Indiquer le championnat, le nom de votre club, et le nom public de votre équipe'
                />
                <Button
                  fullWidth
                  label='Envoyer'
                  disabled={teamRequestValue.length === 0}
                  onClick={() => {
                    create('add_request', {
                      data: {
                        type: 'équipe',
                        value: teamRequestValue,
                      },
                      meta: { useResourcePath: false },
                    });
                    setDisplayTeamAddRequestInput(false);
                  }}
                />
              </FormControl>
            ) : (
              <Typography style={{ textAlign: 'center', width: '100%' }}>Vous ne trouvez pas votre équipe ?</Typography>
            )}
            <Button
              label={displayTeamAddRequestInput ? 'Fermer' : 'Demander à ajouter une équipe'}
              onClick={() => {
                setDisplayTeamAddRequestInput(!displayTeamAddRequestInput);
              }}
            />
          </FormControl>
        )}
      </div>
      <div className='formContainer'>
        <ReferenceArrayInput source='competitors' reference='licensees' label='Joueurs' isRequired>
          <SelectArrayInput
            fullWidth
            label='Joueurs'
            optionText={(choice) => `${choice.identity.firstName} ${choice.identity.lastName}`}
            helperText="Pour sélectionner des joueurs, veillez à créer des licenciés à l'échelle du club concerné."
            isRequired
          />
        </ReferenceArrayInput>
      </div>
    </SimpleForm>
  );
};

export const TeamsEdit = () => (
  <Edit>
    <TeamsForm type='edit' />
  </Edit>
);

export const TeamsCreate = () => (
  <Create>
    <TeamsForm type='create' />
  </Create>
);
