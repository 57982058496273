import { LEGAL_AGENT_FUNCTION, LEGAL_STATUS, VAT_REGIME } from '../../../../models/billingInformation';

export const VAT_REGIME_CHOICES = [
  { id: VAT_REGIME.ASSUJETTI, name: 'Assujetti redevable' },
  { id: VAT_REGIME.ASSUJETTI_EXONERE, name: 'Assujetti exonere' },
  { id: VAT_REGIME.ASSUJETTI_NON_REDEVABLE, name: 'Assujetti non redevable' },
  { id: VAT_REGIME.NON_ASSUJETTI, name: 'Non assujetti' },
];

export const LEGAL_STATUS_CHOICES = [
  { id: LEGAL_STATUS.ASSOCIATION_NOT_LUCRATIVE, name: 'Association loi 1901 à but non lucratif' },
  { id: LEGAL_STATUS.ASSOCIATION_LUCRATIVE, name: 'Association loi 1901 à but lucratif' },
  { id: LEGAL_STATUS.SAOP, name: 'SAOP' },
  { id: LEGAL_STATUS.SASP, name: 'SASP' },
  { id: LEGAL_STATUS.EUSRL, name: 'EUSRL' },
  { id: LEGAL_STATUS.SAS_SASU, name: 'SAS/SASU' },
  { id: LEGAL_STATUS.SARL_EURL, name: 'SARL/EURL' },
  { id: LEGAL_STATUS.SA, name: 'SA' },
];

export const LEGAL_AGENT_FUNCTION_CHOICES = [
  { id: LEGAL_AGENT_FUNCTION.PRESIDENT, name: 'Président' },
  { id: LEGAL_AGENT_FUNCTION.TRESURER, name: 'Trésorier' },
  { id: LEGAL_AGENT_FUNCTION.SALARIED_OFFICER, name: 'Dirigeant Salarié' },
];

export const PHONE_INDICATORS = [
  { name: 'FR (+33)', id: '+33' },
  { name: 'BE (+32)', id: '+32' },
  { name: 'ES (+34)', id: '+34' },
];
