import React, { useCallback } from 'react';
import {
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  Create,
  useRedirect,
  useCreate,
  useNotify,
  useUpdate,
  DateField,
  DateTimeInput,
  FunctionField,
  useTranslate,
  DeleteButton,
  useListContext,
  Button,
} from 'react-admin';
import { SimpleFormToolbar } from '../layout/SimpleFormToolbar';
import './FormStyle.scss';
import './CompetitionStyle.scss';
import Empty, { EmptyCategoryEnum } from '../layout/Empty';
import { TestCompetition } from '../models/competition';

const DisplayRtmpLink = () => {
  const { data } = useListContext<TestCompetition>();
  const notify = useNotify();
  const ingestUrl = data?.find((match) => match.channels.length > 0)?.channels[0].ingestUrl;

  return ingestUrl ? (
    <p style={{ marginTop: 40, fontSize: 16, fontWeight: 'bold' }}>
      Lien rtmp à utiliser pour filmer ces matchs :{' '}
      <Button
        label="Copier l'URL de diffusion"
        variant='outlined'
        onClick={() => {
          navigator.clipboard.writeText(ingestUrl);
          notify("L'URL a bien été copiée dans votre presse papier.", { type: 'success' });
        }}
      />
    </p>
  ) : (
    <></>
  );
};

export const TestCompetitionsList = () => {
  const today = new Date();

  return (
    <List exporter={false} pagination={false} empty={<Empty category={EmptyCategoryEnum.TEST} />}>
      <>
        <div style={{ padding: '10px 50px', fontSize: 14, backgroundColor: '#121212', textAlign: 'center' }}>
          <p>
            Lorsque vous créez un match de test le championnat et les équipes sont remplis automatiquement. Vous n’avez
            donc pas besoin d’avoir créé des licenciés ou une équipe pour tester.
          </p>
          <p>
            <b>Sur l’Application Mobile : </b>les matchs créés ici ne seront pas visibles dans le carrousel, vous pouvez
            accéder au live en tapant le code rencontre dans le champ de recherche prévu à cet effet. Vous pouvez aussi
            tester notre{' '}
            <a
              href='https://alpha.champs.fr/'
              target='_blank'
              style={{ textDecoration: 'none', color: '#64DCBE', fontWeight: 'bold' }}
              rel='noreferrer'
            >
              site de saisie de statistiques
            </a>{' '}
            avec le code rencontre de ce match.
          </p>
          <DisplayRtmpLink />
        </div>
        <Datagrid bulkActionButtons={false}>
          <TextField source='name' label='Match' />
          <DateField source='beginDatetime' label='Date de début' />
          <TextField source='codeRencontre' label='Code rencontre' />
          <FunctionField
            render={(record: TestCompetition) => <EditButton disabled={new Date(record.beginDatetime) < today} />}
          />

          <FunctionField
            render={(record: TestCompetition) => (
              <DeleteButton
                mutationMode='pessimistic'
                confirmTitle=''
                disabled={new Date(record.beginDatetime) < today}
              />
            )}
          />
        </Datagrid>
      </>
    </List>
  );
};

interface CompetitionFormProps {
  type: 'create' | 'edit';
}

const CompetitionsForm = ({ type }: CompetitionFormProps) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const translate = useTranslate();

  const [create] = useCreate();
  const [update] = useUpdate();

  const minDate = new Date().toISOString().slice(0, 16);

  const save = useCallback(
    async (values: any) => {
      try {
        const newData = { ...values };

        if (type == 'create') {
          await create('test_competitions', { data: newData }, { returnPromise: true });
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        } else {
          await update('test_competitions', { id: values.id, data: newData }, { returnPromise: true });
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        }
        redirect('list', 'test_competitions');
      } catch (error: any) {
        if (error.body) {
          // The shape of the returned validation errors must match the shape of the form
          for (const key in error.body) {
            error.body[key] = translate(error.body[key]);
          }
          return error.body;
        }
      }
    },
    [create, notify, redirect, translate, type, update],
  );

  return (
    <SimpleForm onSubmit={save} toolbar={<SimpleFormToolbar redirectTo='list' resource='test_competitions' />}>
      <div className='formContainer'>
        <TextInput
          source='name'
          label='Match'
          style={{ marginRight: '20px' }}
          isRequired
          parse={(v: string) => v}
          placeholder='Ex : Test Diffusion 1'
        />
      </div>
      <div className='formContainer'>
        <DateTimeInput
          source='beginDatetime'
          label='Date et heure de début'
          style={{ marginRight: '20px' }}
          inputProps={{
            min: minDate,
          }}
          isRequired
        />
      </div>
      <div className='formContainer'>
        <TextInput
          source='codeRencontre'
          label='Code rencontre'
          style={{ marginRight: '20px' }}
          isRequired
          parse={(v: string) => v}
          disabled={type == 'edit'}
          helperText='Votre ID match. Saisie libre. le code doit être unique.'
          placeholder='Ex : PSGB03'
        />
      </div>
    </SimpleForm>
  );
};

export const TestCompetitionsEdit = () => {
  return (
    <Edit>
      <CompetitionsForm type='edit' />
    </Edit>
  );
};

export const TestCompetitionsCreate = () => {
  return (
    <Create>
      <CompetitionsForm type='create' />
    </Create>
  );
};
