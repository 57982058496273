import { RaFileType } from './utils';

export enum VAT_REGIME {
  ASSUJETTI = 'ASSUJETTI',
  NON_ASSUJETTI = 'NON_ASSUJETTI',
  ASSUJETTI_EXONERE = 'ASSUJETTI_EXONERE',
  ASSUJETTI_NON_REDEVABLE = 'ASSUJETTI_NON_REDEVABLE',
}

export enum LEGAL_AGENT_FUNCTION {
  PRESIDENT = 'PRESIDENT',
  TRESURER = 'TRESURER',
  SALARIED_OFFICER = 'SALARIED_OFFICER',
}

export enum LEGAL_STATUS {
  ASSOCIATION_NOT_LUCRATIVE = 'ASSOCIATION_NOT_LUCRATIVE',
  ASSOCIATION_LUCRATIVE = 'ASSOCIATION_LUCRATIVE',
  SAOP = 'SAOP',
  SASP = 'SASP',
  EUSRL = 'EUSRL',
  SAS_SASU = 'SAS_SASU',
  SARL_EURL = 'SARL_EURL',
  SA = 'SA',
}

export enum BillingInformationsStatus {
  MISSING = 'MISSING',
  WAITING_FOR_VALIDATIONS = 'WAITING_FOR_VALIDATIONS',
  VALIDATED = 'VALIDATED',
  REFUSED = 'REFUSED',
  WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE',
}

export interface BillingInformations {
  id?: string;
  legalAgentSurname: string;
  legalAgentFirstname: string;
  legalAgentFunction: LEGAL_AGENT_FUNCTION;
  legalAgentEmail: string;
  legalAgentPhoneNumber: string;
  legalStatus: LEGAL_STATUS;
  legalEntityName: string;
  registrationCity: string;
  street: string;
  city: string;
  zipcode: string;
  siret: string;
  vatNumber: string;
  vatRegime: VAT_REGIME;
  acceptSelfBillingOrder: boolean;
  ribStatus: BillingInformationsStatus;
  rib?: File;
  ribName?: string;
  warrantStatus: BillingInformationsStatus;
}

export interface BillingInformationsRaForm extends BillingInformations {
  phoneIndicator?: string;
  ribInputFile?: RaFileType | null;
}

export interface downloadWarrantResponse {
  blob: Blob;
  fileName: string;
}
