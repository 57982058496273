import { AuthProvider, UserIdentity } from 'react-admin';
import { getUserManager } from './services/userManager';
import { getAuthenticationStatus } from './services/hooks';

const cleanup = () => {
  window.localStorage.clear();
  window.history.replaceState({}, window.document.title, window.location.origin);
};

const authProvider: AuthProvider = {
  login: async ({ code, state }: { code?: string; state?: string }) => {
    const { setIsLoggingIn } = getAuthenticationStatus();
    const userManager = getUserManager();

    setIsLoggingIn(true);

    // 1. Redirect to the issuer to ask authentication
    if (!code || !state) {
      await userManager.signinRedirect();
      return; // Do not return anything, the login is still loading
    }
    try {
      const user = await userManager.signinRedirectCallback();
      await userManager.storeUser(user);
      await userManager.clearStaleState();
      cleanup();
      return Promise.resolve('/');
    } catch (error) {
      cleanup();
      return Promise.reject(error);
    }
  },

  logout: async () => {
    const userManager = getUserManager();
    if ((await userManager.getUser()) !== null) {
      await userManager.removeUser();
      const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
      window.location.replace(`${logoutUrl}?next=${window.location.origin}`);
      return `${logoutUrl}?next=${window.location.origin}`;
    }
  },
  checkError: async (error: any) => {
    if (error && error.status === 401) {
      const userManager = getUserManager();

      await userManager.removeUser();
    }
  },

  checkAuth: async () => {
    const userManager = getUserManager();

    const user = await userManager.getUser();
    if (user !== null) {
      return user.expired ? Promise.reject() : Promise.resolve();
    }
    return Promise.reject();
  },

  getPermissions: () => Promise.resolve(),
  getIdentity: async (): Promise<UserIdentity> => {
    const userManager = getUserManager();

    const user = await userManager.getUser();
    if (user === null) {
      return Promise.reject();
    }
    const identity: UserIdentity = {
      id: user?.profile.sub,
      fullName: user?.profile.name,
      avatar: user?.profile.picture,
    };
    return Promise.resolve(identity);
  },
};

export default authProvider;
