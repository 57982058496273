import React, { useRef } from 'react';
import { Team } from '../models/team';
import { Competition } from '../models/competition';
import { useGetList } from 'react-admin';

export interface GMReminder {
  teamId: string;
  teamName: string;
  limitDate: Date;
}

function useGetGMReminders() {
  const { data: teams } = useGetList<Team>('teams');

  const { data: competitions } = useGetList<Competition>('competitions');

  const remindersRef = useRef<{ gmReminders30: GMReminder[]; gmReminders7: GMReminder[] } | undefined>(undefined);

  // check for teams eligible to reminder
  if (teams && competitions && !remindersRef.current) {
    const gmReminders30: GMReminder[] = [];
    const gmReminders7: GMReminder[] = [];

    const todayWithHours = new Date();
    // to ignore hours
    const today = new Date(todayWithHours.getFullYear(), todayWithHours.getMonth(), todayWithHours.getDate());

    teams.forEach((team) => {
      const assignedDate = new Date(team.assignedAt);

      // max date to be eligible to GM
      // we calculate the limit date from UTC to be consistent with what the backend does
      // hours are ignored
      const limitDate = new Date(
        assignedDate.getUTCFullYear(),
        assignedDate.getUTCMonth(),
        assignedDate.getUTCDate() + 59,
      );

      const reminder30Date = new Date(limitDate.getFullYear(), limitDate.getMonth(), limitDate.getDate() - 30);
      const reminder7Date = new Date(limitDate.getFullYear(), limitDate.getMonth(), limitDate.getDate() - 7);

      const teamCompetitions = competitions?.filter((competition) => competition.organizerTeam === team.id);

      // the team is concerned by the reminder if less than 3 competitions have been scheduled for the team
      if (!teamCompetitions || teamCompetitions.length < 3) {
        // add the team to the list of teams eligible to the last 30 or 7 days reminder
        if (reminder30Date <= today && today < reminder7Date) {
          gmReminders30.push({
            teamId: team.id,
            teamName: team.name,
            limitDate: limitDate,
          });
        } else if (today >= reminder7Date && limitDate >= today) {
          gmReminders7.push({
            teamId: team.id,
            teamName: team.name,
            limitDate: limitDate,
          });
        }
      }
    });

    remindersRef.current = { gmReminders30: gmReminders30, gmReminders7: gmReminders7 };
  }

  return remindersRef.current;
}

export default useGetGMReminders;
