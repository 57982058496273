import { stringify } from 'query-string';
import { Identifier, fetchUtils, DataProvider, GetListParams } from 'ra-core';

import { getResourcePath } from './hooks';
import { joinErrorMessages, objectToFormData } from '../utils/apiUtils';
import { DeleteResult } from 'react-admin';
import { getUserManager } from './userManager';
import { BillingInformations, downloadWarrantResponse } from '../models/billingInformation';

export default (
  apiUrl: string,
  httpClient: (
    url: any,
    options?: fetchUtils.Options | undefined,
  ) => Promise<{
    status: number;
    headers: Headers;
    body: string;
    json: any;
  }> = fetchUtils.fetchJson,
): DataProvider => {
  const getOneJson = (resource: string, id: Identifier, useResourcePath = true) => {
    const { resourcePath } = getResourcePath();

    const r = resourcePath.asString;

    let useParamResource = true;
    if (useResourcePath && resourcePath.value.some((x) => x.resource === resource && x.id === id.toString()))
      useParamResource = false;
    const url = `${apiUrl}${r && useResourcePath ? `/${r}` : ''}${useParamResource ? `/${resource}/${id}` : ''}/`;

    return httpClient(url).then((value) => value.json);
  };

  return {
    getList: async (resource, params: GetListParams) => {
      const { resourcePath } = getResourcePath();

      const useResourcePath = !(params?.meta?.useResourcePath === false);
      const r = resourcePath.asString;

      let useParamResource = true;
      if (useResourcePath && resourcePath.value.some((x) => x.resource === resource)) useParamResource = false;
      let url = `${apiUrl}${r && useResourcePath ? `/${r}` : ''}${useParamResource ? `/${resource}` : ''}/`;
      if (params.filter && Object.keys(params.filter).length > 0) {
        url = url + '?';
        for (const filterKey in params.filter) {
          url = url + `${filterKey}=${params.filter[filterKey]}&`;
        }
        url = url.substring(0, url.length - 1); // remove the last '&'
      }

      const { json } = await httpClient(url);

      return {
        data: json,
        total: (json as [any]).length,
      };
    },

    getOne: async (resource, params) => {
      const useResourcePath = !(params?.meta?.useResourcePath === false);

      const data = await getOneJson(resource, params.id, useResourcePath);
      return {
        data,
      };
    },

    getMany: async (resource, params) => {
      const useResourcePath = !(params?.meta?.useResourcePath === false);

      const data = await Promise.all(params.ids.map((id) => getOneJson(resource, id, useResourcePath)));
      const ret = { data: data.flatMap((v) => v) || [] };
      return ret;
    },

    getManyReference: async (resource, params) => {
      const { resourcePath } = getResourcePath();

      const query = {
        [params.target]: params.id,
      };
      const useResourcePath = !(params?.meta?.useResourcePath === false);
      const r = resourcePath.asString;

      const url = `${apiUrl}${r && useResourcePath ? '/' + r : ''}/${resource}/?${stringify(query)}`;

      const { json } = await httpClient(url);
      return {
        data: json,
        total: (json as [any]).length,
      };
    },

    update: async (resource, params) => {
      const { resourcePath } = getResourcePath();

      const useResourcePath = !(params?.meta?.useResourcePath === false);
      const r = resourcePath.asString;
      const url = `${apiUrl}${r && useResourcePath ? '/' + r : ''}/${resource}/${params.id}/`;
      try {
        const { json } = await httpClient(url, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        });
        return {
          data: json,
        };
      } catch (e: any) {
        if (e.status === 400 && e.body) {
          e.body = joinErrorMessages(e.body);
        }
        throw e;
      }
    },

    updateMany: async (resource, params) => {
      const { resourcePath } = getResourcePath();

      const useResourcePath = !(params?.meta?.useResourcePath === false);
      const r = resourcePath.asString;

      const responses = await Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}${r && useResourcePath ? '/' + r : ''} /${resource}/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          }),
        ),
      );
      return { data: responses.map(({ json }) => json.id) };
    },

    create: async (resource, params) => {
      const { resourcePath } = getResourcePath();

      const useResourcePath = !(params?.meta?.useResourcePath === false);
      const additionalRouteParam = params?.meta?.additionalRouteParam;
      const r = resourcePath.asString;
      const url = `${apiUrl}${r && useResourcePath ? '/' + r : ''}/${resource}/${
        additionalRouteParam ? `${additionalRouteParam}/` : ''
      }`;
      const { json, status } = await httpClient(url, {
        method: 'POST',
        body: JSON.stringify(params.data),
      });
      if (status === 204) {
        return { data: { id: params.data?.id || '' } };
      }
      return {
        data: { ...json },
      };
    },

    delete: (resource, params) => {
      const { resourcePath } = getResourcePath();

      const useResourcePath = !(params?.meta?.useResourcePath === false);
      const r = resourcePath.asString;
      const url = `${apiUrl}${r && useResourcePath ? '/' + r : ''}/${resource}/${params.id}/`;
      return httpClient(url, {
        method: 'DELETE',
      }).then(() => ({ data: params.previousData } as DeleteResult));
    },

    deleteMany: async (resource, params) => {
      const { resourcePath } = getResourcePath();

      const useResourcePath = !(params?.meta?.useResourcePath === false);
      const r = resourcePath.asString;

      await Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}${r && useResourcePath ? '/' + r : ''}/${resource}/${id}/`, {
            method: 'DELETE',
          }),
        ),
      );
      return { data: [] };
    },

    getCompetitionsKpi: async (clubId: string, startDate?: string, endDate?: string) => {
      const url =
        startDate && endDate
          ? `${apiUrl}/clubs/${clubId}/competitions/kpi/?start_date=${startDate}&end_date=${endDate}`
          : `${apiUrl}/clubs/${clubId}/competitions/kpi/`;
      const { json } = await httpClient(url);

      return json;
    },

    getTotalIncome: async (clubId: string, startDate?: string, endDate?: string) => {
      const url =
        startDate && endDate
          ? `${apiUrl}/clubs/${clubId}/incomes/total_income/?start_date=${startDate}&end_date=${endDate}`
          : `${apiUrl}/clubs/${clubId}/incomes/total_income/`;
      const { json } = await httpClient(url);

      return json.totalPaid;
    },

    getPendingIncome: async (clubId: string) => {
      const url = `${apiUrl}/clubs/${clubId}/incomes/total_pending/`;
      const { json } = await httpClient(url);

      return json.totalPending;
    },

    getBillingInformations: async (clubId: string) => {
      const url = `${apiUrl}/clubs/${clubId}/billing_informations/`;
      const { json } = await httpClient(url);
      return json;
    },

    downloadBillingInformationWarrant: async (clubId: string): Promise<downloadWarrantResponse | string> => {
      const url = `${apiUrl}/clubs/${clubId}/billing_informations/download/`;
      const userManager = getUserManager();
      const user = await userManager.getUser();
      return new Promise<downloadWarrantResponse | string>((resolve, reject) => {
        fetch(url, { method: 'GET', headers: { Authorization: `Bearer ${user?.access_token}` } })
          .then(async (res) => {
            if (res.status !== 200) reject("Le mandat n'a pas été trouvé.");
            try {
              const fileName = res.headers.get('content-disposition')?.split('filename="')[1].split('"')[0] || "warrant";
              const blob = await res.blob();
              resolve({ blob, fileName });
            } catch {
              reject('Impossible de télécharger le mandat.');
            }
          })
          .catch(() => reject('Une erreur est survenue.'));
      });
    },

    updateBillingInformations: async (clubId: string, data: Partial<BillingInformations>) => {
      const url = `${apiUrl}/clubs/${clubId}/billing_informations/`;
      try {
        const { json } = await httpClient(url, {
          method: 'PATCH',
          body: objectToFormData(data),
        });
        return {
          data: json,
        };
      } catch (e: any) {
        if (e.status === 400 && e.body) {
          e.body = joinErrorMessages(e.body);
        }
        throw e;
      }
    },

    createBillingInformations: async (clubId: string, data: Partial<BillingInformations>) => {
      const url = `${apiUrl}/clubs/${clubId}/billing_informations/`;
      try {
        const { json } = await httpClient(url, {
          method: 'POST',
          body: objectToFormData(data),
        });
        return {
          data: json,
        };
      } catch (e: any) {
        if (e.status === 400 && e.body) {
          e.body = joinErrorMessages(e.body);
        }
        throw e;
      }
    },

    downloadBill: async (clubId: string, id: string): Promise<Blob | string> => {
      const url = `${apiUrl}/clubs/${clubId}/bills/${id}/download/`;
      const userManager = getUserManager();
      const user = await userManager.getUser();
      return new Promise<Blob | string>((resolve, reject) => {
        fetch(url, { method: 'GET', headers: { Authorization: `Bearer ${user?.access_token}` } })
          .then((res) => {
            if (res.status !== 200) reject("La facture n'a pas été trouvée.");
            res
              .blob()
              .then((blob) => resolve(blob))
              .catch(() => reject('Impossible de télécharger le fichier.'));
          })
          .catch(() => reject('Une erreur est survenue.'));
      });
    },
  };
};
