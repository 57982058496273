import React from 'react';
import { BillingInformationsStatus } from "../../models/billingInformation";
import StatusLabel, { StatusLabelVariant } from "../../components/StatusLabel/StatusLabel";

interface BillingInformationStatusLabelProps {
    rib_status?: string;
    warrant_status?: string;
  }
  
  export const BillingInformationStatusLabel = ({ rib_status, warrant_status }: BillingInformationStatusLabelProps) => {
    const data = () => {
      if (rib_status === BillingInformationsStatus.VALIDATED && warrant_status === BillingInformationsStatus.VALIDATED) {
        return { label: 'Validé', variant: StatusLabelVariant.SUCCESS };
      } else if (
        rib_status === BillingInformationsStatus.MISSING ||
        warrant_status === BillingInformationsStatus.MISSING
      ) {
        return { label: 'À compléter', variant: StatusLabelVariant.WARNING };
      } else if (
        rib_status === BillingInformationsStatus.REFUSED ||
        warrant_status === BillingInformationsStatus.REFUSED
      ) {
        return { label: 'Refusé', variant: StatusLabelVariant.DANGER };
      } else if (warrant_status === BillingInformationsStatus.WAITING_FOR_SIGNATURE) {
        return { label: 'En attente de signature', variant: StatusLabelVariant.WARNING };
      } else if (
        warrant_status === BillingInformationsStatus.WAITING_FOR_VALIDATIONS ||
        rib_status === BillingInformationsStatus.WAITING_FOR_VALIDATIONS
      ) {
        return { label: 'En attente de validation', variant: StatusLabelVariant.WARNING };
      }
      return { label: 'À compléter', variant: StatusLabelVariant.WARNING };
    };
  
    return <StatusLabel variant={data().variant} label={data().label} />;
  };
  