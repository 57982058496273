export enum ValidationStatus {
  NOT_VALIDATED = 'NOT_VALIDATED',
  PENDING = 'PENDING',
  VALIDATED = 'VALIDATED',
  REFUSED = 'REFUSED',
  BANNED = 'BANNED',
}

export interface Club {
  id: string;
  name: string;
  association: string;
  sport: string;
  address: {
    id: string;
    street: string;
    city: string;
    zipcode: number;
    country: string;
  };
  socialNetworkUrl: string | null;
  websiteUrl: string | null;
  validationStatus: ValidationStatus;
  statsCode: string;
  //referral
  referralName: string;
  referralFunction: string;
  referralPhoneNumber: string;
  referralEmail: string;
  // substitute
  substituteName: string | null;
  substituteFunction: string | null;
  substitutePhoneNumber: string | null;
  substituteEmail: string | null;
}

export enum IncomeStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
}

export enum CompetitonIncomeTypes {
  ORGANIZER = 'COMPETITION_ORGANIZER',
  PARTICIPANT = 'COMPETITION_PARTICIPANT',
}

export interface CompetitionIncomes {
  id: string;
  status: IncomeStatus;
  paidAt?: string;
  total: number;
  type: CompetitonIncomeTypes;
  competition: { name: string; beginDatetime: string };
  gmIncome?: number;
  videoIncome?: number;
  statsIncome?: number;
  fansIncome?: number;
}

export interface ReferralIncomes {
  id: string;
  status: IncomeStatus;
  paidAt?: string;
  total: number;
  startDatetime: string;
  endDatetime?: string;
  affiliateName: string;
  amountConsumed: number;
}

export interface Bill {
  id: string;
  name: string;
  issueDate: string;
  amount: number;
  club: string;
  billFile: string;
}
