import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SimpleForm, TextInput } from 'ra-ui-materialui';
import { useEffect, useMemo, useState } from 'react';
import { BooleanInput, SelectInput, useNotify } from 'react-admin';
import { formValidations } from './FormHelpers/BillingInformationFormValidations';

import {
  LEGAL_AGENT_FUNCTION_CHOICES,
  LEGAL_STATUS_CHOICES,
  PHONE_INDICATORS,
  VAT_REGIME_CHOICES,
} from './FormHelpers/billingInformations.constant';
import {
  BillingInformations,
  BillingInformationsRaForm,
  BillingInformationsStatus,
} from '../../../models/billingInformation';
import { getCountryByPhoneNumber, removeLeadingZero } from '../../../utils/phoneNumberUtils';
import BillingModal, { EModalType } from './BillingModal';
import { RibInputField } from './RibInputField';
import { SaveCancelToolBar } from './SaveCancelToolBar';
import { AskEditAndDownloadToolBar } from './AskEditAndDownloadToolBar';

interface BillingInformationsFormProps {
  data?: BillingInformations;
  refetchData?: () => void;
  onSubmit?: (fields: BillingInformations) => Promise<any> | undefined;
  onDownloadWarrant?: () => void;
}

const BillingInformationsForm = (props: BillingInformationsFormProps) => {
  const [canEditWarrantFields, setCanEditWarrantFields] = useState(true);
  const [isRibUpdated, setIsRibUpdated] = useState(false);
  const [data, setData] = useState<BillingInformationsRaForm | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState<Record<string, string> | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<EModalType>();
  const notify = useNotify();

  useEffect(() => {
    if (!props.data) return;
    const phoneIndicator = getCountryByPhoneNumber(props.data?.legalAgentPhoneNumber)?.code;
    setData({
      ...props.data,
      phoneIndicator: phoneIndicator || '+33',
      legalAgentPhoneNumber: props.data?.legalAgentPhoneNumber?.replace(phoneIndicator as string, ''),
      ribInputFile: null,
    });
  }, [props.data]);

  useEffect(() => {
    setCanEditWarrantFields(!props.data);
  }, [props.data]);

  const handleSubmit = (body: BillingInformationsRaForm) => {
    /**
     * @param data BillingInformationsRaForm
     * formatting phone number and RIB file
     * @returns BillingInformations | undefined
     */
    const formattedData = (body: BillingInformationsRaForm): BillingInformations => {
      const copyData = { ...body };
      if (copyData.legalAgentPhoneNumber) {
        copyData.legalAgentPhoneNumber = `${copyData.phoneIndicator || ''}${removeLeadingZero(
          copyData.legalAgentPhoneNumber,
        )}`;
        delete copyData.phoneIndicator;
      }
      if (copyData.ribInputFile) {
        copyData.rib = copyData.ribInputFile.rawFile;
        delete copyData.ribInputFile;
      }
      return copyData;
    };
    const formattedBody = formattedData(body);
    const submitModalType = props.data
      ? isRibUpdated && canEditWarrantFields
        ? EModalType.WARRANT_AND_RIB
        : !isRibUpdated
        ? EModalType.WARRANT
        : EModalType.RIB
      : EModalType.CREATE;
    setErrorsOnSubmit(undefined);
    setIsLoading(true);
    props
      .onSubmit?.(formattedBody)
      ?.then(() => {
        setOpenModal(true);
        setModalType(submitModalType);
        setIsRibUpdated(false);
        setCanEditWarrantFields(false);
      })
      .catch((error) => {
        try {
          if (error.body && Object.keys(error.body).length > 0) {
            setErrorsOnSubmit(error.body);
            return;
          }
        } catch (error) {
          notify(`Une erreur s'est produite.`, {
            type: 'error',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const allowedEditStatuses = [BillingInformationsStatus.REFUSED, BillingInformationsStatus.VALIDATED];

  const ribStatusAllowEdit = data ? allowedEditStatuses.includes(data.ribStatus) : true;
  const warrantStatusAllowEdit = data ? allowedEditStatuses.includes(data.warrantStatus) : true;

  const currentToolBar = () => {
    if (canEditWarrantFields || isRibUpdated) {
      return (
        <SaveCancelToolBar
          onCancel={
            props.data
              ? () => {
                  setCanEditWarrantFields(false);
                  setIsRibUpdated(false);
                }
              : undefined
          }
          loading={isLoading}
        />
      );
    }

    return (
      <AskEditAndDownloadToolBar
        isModificationAvailable={ribStatusAllowEdit && warrantStatusAllowEdit}
        onDownloadFile={props.onDownloadWarrant}
        isDownloadAvailable={props.data?.warrantStatus === BillingInformationsStatus.VALIDATED}
        onAskEditClick={() => {
          setOpenModal(true);
          setModalType(EModalType.EDIT);
        }}
      />
    );
  };

  const sortedLegalAgentFunction = useMemo(() => {
    return LEGAL_AGENT_FUNCTION_CHOICES.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, []);

  const sortedLegalStatus = useMemo(() => {
    return LEGAL_STATUS_CHOICES.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, []);

  const sortedVatRegime = useMemo(() => {
    return VAT_REGIME_CHOICES.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, []);

  return (
    <>
      <SimpleForm
        toolbar={currentToolBar()}
        reValidateMode='onBlur'
        criteriaMode='all'
        record={data}
        onSubmit={(body) => handleSubmit(body as BillingInformationsRaForm)}
      >
        <BillingModal
          open={openModal}
          displayCancelButton={modalType === EModalType.EDIT ? true : false}
          onClose={() => setOpenModal(false)}
          type={modalType}
          setCanEditForm={() => setCanEditWarrantFields(true)}
        />
        <Typography
          style={{
            fontWeight: 'bold',
          }}
        >
          Coordonnées du représentant légal du club
        </Typography>
        <Grid container xs={12} md={10} lg={8} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              source={'legalAgentSurname'}
              helperText={errorsOnSubmit?.['legalAgentSurname'] || ''}
              error={errorsOnSubmit?.['legalAgentSurname'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['legalAgentSurname'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Nom'
              fullWidth
              validate={formValidations['legalAgentSurname']}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              source={'legalAgentFirstname'}
              label='Prénom'
              helperText={errorsOnSubmit?.['legalAgentFirstname'] || ''}
              error={errorsOnSubmit?.['legalAgentFirstname'] !== undefined}
              color={errorsOnSubmit?.['legalAgentFirstname'] !== undefined ? 'error' : 'primary'}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['legalAgentFirstname'] !== undefined ? '#f44336' : '#f44336' },
              }}
              fullWidth
              validate={formValidations['legalAgentFirstname']}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <SelectInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              choices={sortedLegalAgentFunction}
              source={'legalAgentFunction'}
              helperText={errorsOnSubmit?.['legalAgentFunction'] || ''}
              error={errorsOnSubmit?.['legalAgentFunction'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['legalAgentFunction'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Fonction'
              fullWidth
              validate={formValidations['legalAgentFunction']}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <SelectInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              choices={PHONE_INDICATORS}
              source={'phoneIndicator'}
              label='Indicatif'
              defaultValue={'+33'}
              helperText={''}
              fullWidth
              validate={formValidations['phoneIndicator']}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              source={'legalAgentPhoneNumber'}
              color={errorsOnSubmit?.['legalAgentPhoneNumber'] !== undefined ? 'error' : 'primary'}
              label='Numéro de téléphone'
              placeholder='ex: 642557849'
              helperText={errorsOnSubmit?.['legalAgentPhoneNumber'] || ''}
              error={errorsOnSubmit?.['legalAgentPhoneNumber'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['legalAgentPhoneNumber'] !== undefined ? '#f44336' : '#f44336' },
              }}
              fullWidth
              validate={formValidations['legalAgentPhoneNumber']}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              source={'legalAgentEmail'}
              helperText={errorsOnSubmit?.['legalAgentEmail'] || ''}
              error={errorsOnSubmit?.['legalAgentEmail'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['legalAgentEmail'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Adresse mail'
              fullWidth
              validate={formValidations['legalAgentEmail']}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 15,
              marginBottom: -15,
            }}
          >
            <Typography style={{ fontWeight: 'bold' }}>{`Adresse de l'établissement habilité à facturer`}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              source={'legalEntityName'}
              helperText={errorsOnSubmit?.['legalEntityName'] || ''}
              error={errorsOnSubmit?.['legalEntityName'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['legalEntityName'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Nom du club'
              fullWidth
              validate={formValidations['legalEntityName']}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              choices={sortedLegalStatus}
              source={'legalStatus'}
              helperText={errorsOnSubmit?.['legalStatus'] || ''}
              error={errorsOnSubmit?.['legalStatus'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['legalStatus'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Statut juridique'
              fullWidth
              validate={formValidations['legalStatus']}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              source={'street'}
              helperText={errorsOnSubmit?.['street'] || ''}
              error={errorsOnSubmit?.['street'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['street'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Adresse'
              fullWidth
              validate={formValidations['street']}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              source={'zipcode'}
              parse={(v: string) => v}
              helperText={errorsOnSubmit?.['zipcode'] || ''}
              error={errorsOnSubmit?.['zipcode'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['zipcode'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Code postal'
              fullWidth
              validate={formValidations['zipcode']}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              source={'city'}
              helperText={errorsOnSubmit?.['city'] || ''}
              error={errorsOnSubmit?.['city'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['city'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Ville'
              fullWidth
              validate={formValidations['city']}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              source={'siret'}
              parse={(v: string) => v}
              helperText={errorsOnSubmit?.['siret'] || ''}
              error={errorsOnSubmit?.['siret'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['siret'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Siret'
              fullWidth
              validate={formValidations['siret']}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              source={'registrationCity'}
              parse={(v: string) => v}
              helperText={errorsOnSubmit?.['registrationCity'] || ''}
              error={errorsOnSubmit?.['registrationCity'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['registrationCity'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Immatriculé à (ville)'
              fullWidth
              validate={formValidations['registrationCity']}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              choices={sortedVatRegime}
              source={'vatRegime'}
              helperText={errorsOnSubmit?.['vatRegime'] || ''}
              error={errorsOnSubmit?.['vatRegime'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['vatRegime'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='Régime de TVA'
              fullWidth
              validate={formValidations['vatRegime']}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              InputProps={{
                disabled: !canEditWarrantFields,
              }}
              parse={(v: string) => v}
              source={'vatNumber'}
              helperText={errorsOnSubmit?.['vatNumber'] || ''}
              error={errorsOnSubmit?.['vatNumber'] !== undefined}
              FormHelperTextProps={{
                style: { color: errorsOnSubmit?.['vatNumber'] !== undefined ? '#f44336' : '#f44336' },
              }}
              label='TVA (si applicable)'
              fullWidth
              validate={formValidations['vatNumber']}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 25 }}>
            <RibInputField
              disabled={!ribStatusAllowEdit || !warrantStatusAllowEdit}
              updateLabel={canEditWarrantFields ? 'Modifier le RIB' : 'Modifier uniquement le RIB'}
              addLabel='Importer un RIB *'
              fileInputProps={{
                source: 'ribInputFile',
                accept: '.pdf',
                helperText: 'Format Pdf uniquement',
                onChange: (e: HTMLInputElement) => {
                  setIsRibUpdated(e instanceof File);
                },
                validate: props.data
                  ? formValidations['ribInputFileNotRequired']
                  : formValidations['ribInputFileRequired'],
              }}
              originalFileName={data?.ribName}
              fileNameHelperText='Tout nouveau RIB importé doit être vérifié par nos soins'
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 22,
              marginBottom: -15,
            }}
          >
            <Typography
              style={{
                fontSize: 16,
              }}
            >
              {`Ci-après désigné "Le Mandant", d'une part`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex'}}>
              <BooleanInput
                disabled={!canEditWarrantFields}
                source={'acceptSelfBillingOrder'}
                label=''
                helperText={errorsOnSubmit?.['acceptSelfBillingOrder'] || ''}
                style={{
                  color:
                    errorsOnSubmit?.['acceptSelfBillingOrder'] && !data?.acceptSelfBillingOrder ? '#f44336' : undefined,
                }}
                validate={formValidations['acceptSelfBillingOrder']}
              />
              <Typography
                style={{
                  fontSize: 16,
                  marginTop: 8,
                  marginLeft: -12,
                }}
              >
                {`Accepter de confier le présent mandat d'auto-facturation, au Mandataire *`}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </SimpleForm>
    </>
  );
};

export default BillingInformationsForm;
