export function joinErrorMessages(obj: any): any {
  for (const key of Object.keys(obj)) {
    if (obj[key] instanceof Array) {
      obj[key] = obj[key].join(',');
    } else if (typeof obj[key] === 'object') {
      obj[key] = joinErrorMessages(obj[key]);
    }
  }
  return obj;
}

export const objectToFormData = (data: Record<string, any>, excludeKeys?: string[]): FormData => {
  const formData = new FormData();
  Object.entries(data).map(([key, value]) => {
    if ((!excludeKeys || !excludeKeys.includes(key)) && value !== undefined) {
      formData.append(key, value);
    }
  });
  return formData;
};
