import * as React from 'react';
import { AppBar, useRedirect, IconButtonWithTooltip } from 'react-admin';
import Typography from '@mui/material/Typography';
import { useResourcePath } from '../services/hooks';
import { ReactComponent as Logo } from './logo.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import { useGetOne } from 'react-admin';
import './AdminStyle.scss';
import { Club } from '../models/club';

export const AdminAppBar = () => {
  const redirect = useRedirect();
  const resourcePath = useResourcePath();
  const clubId = resourcePath.resourcePath.value.find((v) => v.resource === 'clubs')?.id;

  const { data: club } = useGetOne<Club>('clubs', { id: clubId || '' }, { enabled: clubId ? true : false });

  return (
    <AppBar
      sx={{
        backgroundColor: '#141414',
        // to hide burger button
        '& .RaAppBar-menuButton': {
          display: 'none',
        },
      }}
    >
      {resourcePath.resourcePath.value.length === 1 ? (
        <>
          <IconButtonWithTooltip
            label='retour'
            color='primary'
            onClick={() => {
              resourcePath.clearResourcePath();
              redirect('/clubs');
            }}
          >
            <ArrowBackIcon />
          </IconButtonWithTooltip>
          <Typography variant='h6' color='inherit'>
            Retour aux Clubs
          </Typography>
        </>
      ) : (
        <Typography variant='h6' color='inherit' style={{ marginLeft: '20px' }}>
          Vos Clubs
        </Typography>
      )}

      <Logo style={{ flex: 1 }} height={64} />

      <div className='codeClub'>
        {club?.statsCode && (
          <Tooltip title='Code club' placement='bottom-end'>
            <p>{club.statsCode}</p>
          </Tooltip>
        )}
      </div>
    </AppBar>
  );
};
