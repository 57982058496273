import React from 'react';
import CreateIcon from '@mui/icons-material/Create';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, SaveButton, Toolbar } from 'react-admin';

interface AskEditAndDownloadToolBarProps {
  onDownloadFile?: () => void;
  isModificationAvailable?: boolean;
  isDownloadAvailable?: boolean;
  onAskEditClick?: () => void;
}

export const AskEditAndDownloadToolBar = (props: AskEditAndDownloadToolBarProps) => (
  <Toolbar
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      columnGap: 10,
    }}
  >
    {props.onDownloadFile && props.isDownloadAvailable && (
      <SaveButton
        alwaysEnable={true}
        onClick={(e) => {
          e.preventDefault();
          props.onDownloadFile?.();
        }}
        icon={<DownloadIcon />}
        variant='contained'
        label='Télécharger mon mandat PDF'
      />
    )}
    <Button
      onClick={(e) => {
        e.preventDefault();
        props.onAskEditClick?.();
      }}
      style={{
        padding: 8,
      }}
      disabled={!props.isModificationAvailable}
      color='primary'
      variant='outlined'
      startIcon={<CreateIcon />}
      label='Modifier les données du mandat'
    />
  </Toolbar>
);
