export const phoneFormats: {
  [key: string]: { code: string; country: string; pattern: RegExp; codeLang: string; placeholder?: string }[];
} = {
  Venezuela: [{ code: '+58', country: 'Venezuela', pattern: /^\+58\d{10}$/, codeLang: 'VE' }],
  Afghanistan: [{ code: '+93', country: 'Afghanistan', pattern: /^\+93\d{9}$/, codeLang: 'AF' }],
  'South Africa': [{ code: '+27', country: 'South Africa', pattern: /^\+27\d{9}$/, codeLang: 'ZA' }],
  Albania: [{ code: '+355', country: 'Albania', pattern: /^\+355\d{8}$/, codeLang: 'AL' }],
  Algeria: [{ code: '+213', country: 'Algeria', pattern: /^\+213\d{9,10}$/, codeLang: 'DZ' }],
  Germany: [
    { code: '+49', country: 'Germany', pattern: /^\+49\d{10}$/, codeLang: 'DE' }, // Germany
    { code: '+49', country: 'Germany (Mobile)', pattern: /^\+491\d{11}$/, codeLang: 'DE' },
  ],
  Argentina: [{ code: '+54', country: 'Argentina', pattern: /^\+54\d{10,11}$/, codeLang: 'AR' }],
  Australia: [{ code: '+61', country: 'Australia', pattern: /^\+61\d{9}$/, codeLang: 'AU' }],
  Brazil: [{ code: '+55', country: 'Brazil', pattern: /^\+55\d{10,11}$/, codeLang: 'BR' }],
  Canada: [{ code: '+1', country: 'Canada', pattern: /^\+1\d{10}$/, codeLang: 'CA' }],
  China: [{ code: '+86', country: 'China', pattern: /^\+86\d{11}$/, codeLang: 'CN' }],
  Egypt: [{ code: '+20', country: 'Egypt', pattern: /^\+20\d{10}$/, codeLang: 'EG' }],
  France: [{ code: '+33', country: 'France', pattern: /^\+33\d{9}$/, codeLang: 'FR' }],
  India: [{ code: '+91', country: 'India', pattern: /^\+91\d{10}$/, codeLang: 'IN' }],
  Indonesia: [{ code: '+62', country: 'Indonesia', pattern: /^\+62\d{9,10,11}$/, codeLang: 'ID' }],
  Italy: [{ code: '+39', country: 'Italy', pattern: /^\+39\d{10}$/, codeLang: 'IT' }],
  Japan: [{ code: '+81', country: 'Japan', pattern: /^\+81\d{10}$/, codeLang: 'JP' }],
  Mexico: [{ code: '+52', country: 'Mexico', pattern: /^\+52\d{10}$/, codeLang: 'MX' }],
  Nigeria: [{ code: '+234', country: 'Nigeria', pattern: /^\+234\d{10,11}$/, codeLang: 'NG' }],
  Russia: [{ code: '+7', country: 'Russia', pattern: /^\+7\d{10}$/, codeLang: 'RU' }],
  'South Korea': [{ code: '+82', country: 'South Korea', pattern: /^\+82\d{9,10}$/, codeLang: 'KR' }],
  Spain: [{ code: '+34', country: 'Spain', pattern: /^\+34\d{9}$/, codeLang: 'ES' }],
  Turkey: [{ code: '+90', country: 'Turkey', pattern: /^\+90\d{10}$/, codeLang: 'TR' }],
  'United Kingdom': [{ code: '+44', country: 'United Kingdom', pattern: /^\+44\d{10}$/, codeLang: 'GB' }],
  'United States': [{ code: '+1', country: 'United States', pattern: /^\+1\d{10}$/, codeLang: 'US' }],
  Vietnam: [{ code: '+84', country: 'Vietnam', pattern: /^\+84\d{9,10}$/, codeLang: 'VN' }],
  Philippines: [{ code: '+63', country: 'Philippines', pattern: /^\+63\d{10}$/, codeLang: 'PH' }],
  Pakistan: [{ code: '+92', country: 'Pakistan', pattern: /^\+92\d{10}$/, codeLang: 'PK' }],
  Bangladesh: [{ code: '+880', country: 'Bangladesh', pattern: /^\+880\d{10}$/, codeLang: 'BD' }],
  Iran: [{ code: '+98', country: 'Iran', pattern: /^\+98\d{10}$/, codeLang: 'IR' }],
  Thailand: [{ code: '+66', country: 'Thailand', pattern: /^\+66\d{9,10}$/, codeLang: 'TH' }],
  Myanmar: [{ code: '+95', country: 'Myanmar', pattern: /^\+95\d{9,10}$/, codeLang: 'MM' }],
  'Saudi Arabia': [{ code: '+966', country: 'Saudi Arabia', pattern: /^\+966\d{9,10}$/, codeLang: 'SA' }],
  Malaysia: [{ code: '+60', country: 'Malaysia', pattern: /^\+60\d{9,10}$/, codeLang: 'MY' }],
  Nepal: [{ code: '+977', country: 'Nepal', pattern: /^\+977\d{9,10}$/, codeLang: 'NP' }],
  'Sri Lanka': [{ code: '+94', country: 'Sri Lanka', pattern: /^\+94\d{9,10}$/, codeLang: 'LK' }],
  'United Arab Emirates': [
    { code: '+971', country: 'United Arab Emirates', pattern: /^\+971\d{9,10}$/, codeLang: 'AE' },
  ],
  Singapore: [{ code: '+65', country: 'Singapore', pattern: /^\+65\d{8}$/, codeLang: 'SG' }],
  'Hong Kong': [{ code: '+852', country: 'Hong Kong', pattern: /^\+852\d{8}$/, codeLang: 'HK' }],
  'New Zealand': [{ code: '+64', country: 'New Zealand', pattern: /^\+64\d{8,9}$/, codeLang: 'NZ' }],
  Israel: [{ code: '+972', country: 'Israel', pattern: /^\+972\d{9}$/, codeLang: 'IL' }],
  Portugal: [{ code: '+351', country: 'Portugal', pattern: /^\+351\d{9}$/, codeLang: 'PT' }],
  Greece: [{ code: '+30', country: 'Greece', pattern: /^\+30\d{10}$/, codeLang: 'GR' }],
  Netherlands: [{ code: '+31', country: 'Netherlands', pattern: /^\+31\d{9}$/, codeLang: 'NL' }],
  Sweden: [{ code: '+46', country: 'Sweden', pattern: /^\+46\d{9,10}$/, codeLang: 'SE' }],
  Switzerland: [{ code: '+41', country: 'Switzerland', pattern: /^\+41\d{9}$/, codeLang: 'CH' }],
  Belgium: [{ code: '+32', country: 'Belgium', pattern: /^\+32\d{8,9}$/, codeLang: 'BE' }],
  Austria: [{ code: '+43', country: 'Austria', pattern: /^\+43\d{9,10}$/, codeLang: 'AT' }],
  Denmark: [{ code: '+45', country: 'Denmark', pattern: /^\+45\d{8}$/, codeLang: 'DK' }],
  Norway: [{ code: '+47', country: 'Norway', pattern: /^\+47\d{8}$/, codeLang: 'NO' }],
  Finland: [{ code: '+358', country: 'Finland', pattern: /^\+358\d{8,9}$/, codeLang: 'FI' }],
  Ireland: [{ code: '+353', country: 'Ireland', pattern: /^\+353\d{9,10}$/, codeLang: 'IE' }],
  'United State': [
    { code: '+1', country: 'United States', pattern: /^\+1\s?\(?\d{3}\)?\s?\d{3}-?\d{4}$/, codeLang: 'US' },
  ],
};
