import React, { useState } from 'react';
import './Dashboard.scss';
import { Loading, useDataProvider, Link, Button } from 'react-admin';
import { useQuery } from 'react-query';
import { useResourcePath } from '../services/hooks';
import useGetGMReminders, { GMReminder } from '../services/useGetGMReminders';
import { Dialog, styled } from '@material-ui/core';

export default function Dashboard() {
  const resourcePath = useResourcePath();
  const clubId = resourcePath.resourcePath.value[0].id || '';

  const gmReminders = useGetGMReminders();

  const [isGMReminderSeen, setIsGMReminderSeen] = useState<boolean>(false);

  let gmReminders30: GMReminder[] = [];
  if (!isGMReminderSeen && gmReminders && gmReminders?.gmReminders30.length > 0) {
    const storageTeamsReminded30String = localStorage.getItem(clubId + 'teamsReminded30');
    const storageTeamsReminded30: { [id: string]: boolean } = storageTeamsReminded30String
      ? JSON.parse(storageTeamsReminded30String)
      : {};
    gmReminders30 = gmReminders?.gmReminders30.filter((reminder) => !storageTeamsReminded30[reminder.teamId]) || [];
  }

  let gmReminders7: GMReminder[] = [];
  if (!isGMReminderSeen && gmReminders && gmReminders?.gmReminders7.length > 0) {
    const storageTeamsReminded7String = localStorage.getItem(clubId + 'teamsReminded7');
    const storageTeamsReminded7: { [id: string]: boolean } = storageTeamsReminded7String
      ? JSON.parse(storageTeamsReminded7String)
      : {};
    gmReminders7 = gmReminders?.gmReminders7.filter((reminder) => !storageTeamsReminded7[reminder.teamId]) || [];
  }

  const computeGMReminderLimitDate = (reminders: GMReminder[]) => {
    // display the closest limit date if multiple teams are eligible
    if (reminders.length > 0) {
      let limitDate = reminders[0].limitDate;
      reminders.forEach((reminder) => (limitDate = reminder.limitDate < limitDate ? reminder.limitDate : limitDate));
      return limitDate.toLocaleString('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      });
    }
  };

  const handleCloseGMReminder = () => {
    // when modal is closed we save the teams already reminded to not display them again
    if (gmReminders?.gmReminders30) {
      const teamsReminded30: { [id: string]: boolean } = {};
      gmReminders.gmReminders30.forEach((gmReminder: GMReminder) => {
        teamsReminded30[gmReminder.teamId] = true;
      });
      localStorage.setItem(clubId + 'teamsReminded30', JSON.stringify(teamsReminded30));
    }

    if (gmReminders?.gmReminders7) {
      const teamsReminded7: { [id: string]: boolean } = {};
      gmReminders.gmReminders7.forEach((gmReminder: GMReminder) => {
        teamsReminded7[gmReminder.teamId] = true;
      });
      localStorage.setItem(clubId + 'teamsReminded7', JSON.stringify(teamsReminded7));
    }

    setIsGMReminderSeen(true);
  };

  const today = new Date();

  interface Kpi {
    nbViewers: number | null;
    nbCompetitions: number;
  }

  const dataProvider = useDataProvider();

  const sevenDaysBefore = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString();

  const {
    data: totalIncome,
    error: incomeError,
    isLoading: incomeIsLoading,
  } = useQuery<number>(['getTotalIncome'], () => dataProvider.getTotalIncome(clubId));

  const {
    data: incomeLastWeek,
    error: incomeLastWeekError,
    isLoading: incomeLastWeekIsLoading,
  } = useQuery<number>(['getIncomeByDate'], () =>
    dataProvider.getTotalIncome(clubId, sevenDaysBefore, today.toISOString()),
  );

  const {
    data: kpi,
    error: kpiError,
    isLoading: kpiIsLoading,
  } = useQuery<Kpi>(['getCompetitionsKpi'], () => dataProvider.getCompetitionsKpi(clubId));

  const {
    data: kpiLastWeek,
    error: kpiLastWeekError,
    isLoading: kpiLastWeekIsLoading,
  } = useQuery<Kpi>(['getCompetitionsKpiLastWeek'], () =>
    dataProvider.getCompetitionsKpi(clubId, sevenDaysBefore, today.toISOString()),
  );

  if (kpiIsLoading || kpiLastWeekIsLoading || incomeIsLoading || incomeLastWeekIsLoading) {
    return <Loading />;
  }

  // if we can't display any bubble category
  if ((kpiError || incomeError) && (kpiLastWeekError || incomeLastWeekError)) {
    return <div className='dashboard'>Une erreur s&#39;est produite</div>;
  }

  const ReminderDialog = styled(Dialog)({
    '& .MuiPaper-root': {
      textAlign: 'center',
      backgroundColor: '#141414',
      color: 'white',
      padding: 20,
      border: '3px solid #64DCBE',
      borderRadius: 20,
    },
  });

  return (
    <>
      <ReminderDialog
        open={!isGMReminderSeen && (gmReminders30.length > 0 || gmReminders7.length > 0) ? true : false}
        onClose={handleCloseGMReminder}
      >
        <p className='reminderTitle'>RAPPEL : Revenu Minimum Garanti</p>
        {gmReminders7.length > 0 && (
          <p className='reminderText'>
            <b>ATTENTION : Dernier(s) jour(s) </b> pour programmer les matchs de{' '}
            {gmReminders7.length < 2 ? 'votre équipe' : 'vos équipes'}{' '}
            <b>{gmReminders7.map((reminder) => reminder.teamName).join(', ')}</b> et être éligible au REVENU MINIMUM
            GARANTI. <br />
            Vous avez jusqu&#39;au : {computeGMReminderLimitDate(gmReminders7)} inclus !
          </p>
        )}
        {gmReminders30.length > 0 && (
          <p className='reminderText'>
            Programmez vite les futurs matchs de {gmReminders30.length < 2 ? 'votre équipe' : 'vos équipes'}{' '}
            <b>{gmReminders30.map((reminder) => reminder.teamName).join(', ')}</b> <br />
            Vous avez jusqu&#39;au : {computeGMReminderLimitDate(gmReminders30)} inclus ! <br />
            Ils seront éligibles au REVENU MINIMUM GARANTI.
          </p>
        )}
        <p>
          RDV dans l&#39;onglet{' '}
          <Link to='/competitions' style={{ textDecoration: 'none', color: '#64DCBE' }}>
            Matchs
          </Link>
        </p>
        <Button
          sx={{
            width: 150,
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: '#64DCBE',
            color: 'white',
          }}
          label='Fermer'
          onClick={handleCloseGMReminder}
        />
      </ReminderDialog>
      <div className='dashboard'>
        {kpi && totalIncome != undefined && (
          <>
            <h3>Résumé depuis votre inscription</h3>
            <section className='cardsContainer'>
              <article className='card'>
                <p>Matchs diffusés par votre club</p>
                <h4>{kpi.nbCompetitions}</h4>
              </article>
              <article className='card'>
                <p>Viewers cumulés sur vos matchs</p>
                <h4>{kpi.nbViewers ? kpi.nbViewers : 0}</h4>
              </article>
              {/* <article className='card'>
                <p>Revenus cumulés</p>
                <h4>{totalIncome} €</h4>
              </article> */}
            </section>
          </>
        )}

        {kpiLastWeek && incomeLastWeek != undefined && (
          <>
            <h3>Les 7 derniers jours</h3>
            <section className='cardsContainer'>
              <article className='card'>
                <p>Matchs diffusés par votre club</p>
                <h4>{kpiLastWeek.nbCompetitions}</h4>
              </article>
              <article className='card'>
                <p>Viewers cumulés sur vos matchs</p>
                <h4>{kpiLastWeek.nbViewers ? kpiLastWeek.nbViewers : 0}</h4>
              </article>
              {/* <article className='card'>
                <p>Revenus cumulés</p>
                <h4>{incomeLastWeek} €</h4>
              </article> */}
            </section>
          </>
        )}
      </div>
    </>
  );
}
