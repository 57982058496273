import { phoneFormats } from './phonenumber.constant';

type PhoneNumberInfo = {
  code: string;
  phone: string;
};

export const getCountryByPhoneNumber = (phoneNumber: string | undefined): PhoneNumberInfo | null => {
  if (!phoneNumber) return null;
  for (const country in phoneFormats) {
    const formats = phoneFormats[country];
    for (const format of formats) {
      if (format.pattern.test(phoneNumber)) {
        return { code: format.code, phone: phoneNumber };
      }
    }
  }
  return null;
};


export const removeLeadingZero = (phoneNumber: string) => {
  return phoneNumber.replace(/^0+/, '');
};
